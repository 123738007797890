import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Collections from "./Collections";
import Hero from "./Hero";
import ProductsContainer from "./ProductsContainer";
import SampleSale from "./SampleSale";
// import Reviews from "./Reviews";
import PinkBar from "./PinkBar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay } from "swiper";

function Home() {
  const { isLoading } = useSelector((store) => store.products);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Home";
  }, []);

  if (isLoading) {
    return (
      <div className="container-fluid">
        <Skeleton height={200} />
        <div className="row m-3">
          <div className="col-md-12 text-center">
            {/* <Skeleton height={60} width={300} /> */}
            <Skeleton height={10} className="my-3" />
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return (
                <SwiperSlide key={item}>
                  <article key={item} className="m-3">
                    <Skeleton height={250} width={350} />
                    <Skeleton height={30} width={350} />
                    <Skeleton height={30} width={150} className="mt-3" />
                  </article>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
              return (
                <SwiperSlide key={item}>
                  <article key={item} className="m-3">
                    <Skeleton height={250} width={350} />
                    <Skeleton height={30} width={350} />
                    <Skeleton height={30} width={150} className="mt-3" />
                  </article>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }

  return (
    <>
      <Hero />
      <ProductsContainer />
      <PinkBar />
      <Collections />
      <PinkBar />
      <SampleSale />
      {/* <Reviews /> */}
    </>
  );
}

export default Home;
