import React, { useState, useEffect } from "react";
import { CouponSection } from "../styles/Admin.styled";
import { edit, deleteIcon } from "../../icons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { saveCoupon, deleteCoupon } from "../../utils/firebaseFunctions";

function Coupons() {
  const { coupons } = useSelector((store) => store.reviews);
  const [coupon, setCoupon] = useState({ code: "", amount: "", id: uuidv4() });
  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCoupon({ ...coupon, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!coupon.amount || !coupon.code) {
      setMsg("All fields required!");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } else {
      saveCoupon(coupon, coupon.id);
      setCoupon({ code: "", amount: "", id: uuidv4() });
    }
  };
  const handleEdit = (data) => {
    setCoupon({ code: data.code, amount: data.amount, id: data.id });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Admin-Coupons";
  }, []);

  return (
    <CouponSection>
      <div className="add-coupon">
        <h1>Add a coupon</h1>
        <h6>{msg}</h6>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="coupon-code"
            placeholder="Coupon Code"
            name="code"
            value={coupon.code}
            onChange={handleChange}
          />
          <input
            type="text"
            id="coupon-amount"
            placeholder="Amount"
            name="amount"
            value={coupon.amount}
            onChange={handleChange}
          />
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>

      <table>
        <thead>
          <tr>
            <th className="name">Code</th>
            <th className="category">Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {coupons?.map((item) => {
            return (
              <tr key={item.id}>
                <td className="name">{item.code}</td>
                <td className="category">{item.amount}</td>
                <td className="action">
                  <span className="editIcon" onClick={() => handleEdit(item)}>
                    {edit}
                  </span>
                  <span
                    className="deleteIcon"
                    onClick={() => deleteCoupon(item.id)}
                  >
                    {deleteIcon}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CouponSection>
  );
}

export default Coupons;
