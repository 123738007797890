import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedOrderStatus({ children }) {
  const { orderDetails } = useSelector((store) => store.cart);

  if (Object.keys(orderDetails).length > 0) {
    return children;
  }
  return <Navigate to="/" />;
}

export default ProtectedOrderStatus;
