import styled from "styled-components";

export const AdminProduct = styled.section`
  margin: 2rem auto;
  width: 1440px;

  .top {
    display: flex;
    justify-content: space-between;
  }
  .top h6 {
    opacity: 0.6;
  }
  .product-add-search {
    display: flex;
  }
  .add-new-product {
    border: 0;
    background-color: #f5cbae;
    color: white;
    border-radius: 4px;
    width: 15rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  .search-product {
    position: relative;
  }
  .search-product input {
    outline: 0;
    border: 2px solid rgba(0, 0, 0, 0.507);
    padding-left: 2.2rem;
    opacity: 0.5;
    border-radius: 4px;
    width: 15rem;
    height: 2.5rem;
  }
  .search-product svg {
    cursor: pointer;
    position: absolute;
    opacity: 0.5;
    height: 2rem;
    top: 2px;
    left: 2px;
  }

  table {
    width: 70rem;
    margin-top: 4rem;
  }
  th {
    padding-bottom: 1rem;
    font-weight: 600;
  }
  td {
    border-top: 1px solid rgba(83, 83, 83, 0.192);
    margin: 4px;
    padding: 2px;
  }
  .disabled {
    text-decoration: line-through;
  }
  .id {
    font-weight: 600;
  }
  .state-live span {
    padding: 0.1rem 1rem;
    color: #81d9e2;
    background-color: #c9f7f5;
    border-radius: 10px;
  }
  .action {
    display: flex;
  }

  .action svg {
    cursor: pointer;
    height: 1.5rem;
    margin-right: 4px;
    border-radius: 4px;
  }
  .editIcon svg {
    color: rgb(15, 15, 163);
    border: 2px solid rgb(15, 15, 163);
  }
  .closeIcon svg {
    color: orange;
    border: 2px solid orange;
  }
  .deleteIcon svg {
    color: red;
    border: 2px solid red;
  }
  .confirm-delete {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    background-color: white;
    width: 30rem;
    height: 20rem;
    box-shadow: 1px 1px 10px 1px grey;
  }
  .confirm-delete p {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  .confirm-delete svg {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    height: 2rem;
    color: red;
    border: 1px solid red;
    border-radius: 4px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
  }
  .btns button {
    border: 1px solid black;
    height: 2rem;
    width: 5rem;
  }

  .add-product-modal {
    pointer-events: all;
    z-index: 6;
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(114, 114, 114, 0.137);
  }
  .add-product-modal-close {
    display: none;
  }
`;
