import React, { useEffect, useCallback } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Routers from "./Routers";
import { useDispatch } from "react-redux";
import { fetchProducts } from "./features/product/productSlice";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { addUserSession } from "./utils/firebaseFunctions";

function App() {
  const dispatch = useDispatch();

  const fetchAllProducts = useCallback(async () => {
    const q = query(collection(db, "Products"), orderBy("timestamp", "desc"));
    onSnapshot(q, (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      const products = arr.filter(
        (item) => item.active === true && item?.images.length > 0
      );
      dispatch(fetchProducts(products));
    });
  }, [dispatch]);

  useEffect(() => {
    fetchAllProducts();
  }, [fetchAllProducts]);

  useEffect(() => {
    if (!localStorage.getItem("userCount")) {
      localStorage.setItem(
        "userCount",
        JSON.stringify({ date: new Date().toDateString() })
      );
      addUserSession();
    } else if (
      JSON.parse(localStorage.getItem("userCount")).date !==
      new Date().toDateString()
    ) {
      localStorage.setItem(
        "userCount",
        JSON.stringify({ date: new Date().toDateString() })
      );
      addUserSession();
    }
  }, []);

  return (
    <>
      <Routers />
    </>
  );
}

export default App;
