import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SizeChart() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Size Chart";
  }, []);
  return (
    <>
      {/* <div className="bg"></div> */}
      <section style={{ minHeight: "45vh" }}>
        <LazyLoadImage
          src="./images/Size chart-01.jpg"
          placeholderSrc="/images/1-min.jpg"
          className="img-fluid"
          alt="size chart"
          effect="blur"
        />
        {/* <img src="./images/Size chart-01.jpg" alt="" className="img-fluid" /> */}
      </section>
    </>
  );
}

export default SizeChart;
