import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSingleOrder } from "../../features/product/productSlice";
import { open } from "../../icons";
import dayjs from "dayjs";
import Dropdown from "react-bootstrap/Dropdown";
import { changeStatus } from "../../utils/firebaseFunctions";

function OrdersList({ day, month, year, allOrders, setAllOrders }) {
  const dispatch = useDispatch();
  const { orders } = useSelector((store) => store.products);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (day && month && year) {
      setAllOrders(
        orders?.filter((item) =>
          dayjs(item.date).isSame(dayjs(`${day} ${month}, ${year}`))
        )
      );
    }
  }, [day, month, year, orders, setAllOrders]);

  if (day && month && year) {
    return (
      <table>
        <thead>
          <tr>
            <th className="order-no">ORDER NO.</th>
            <th className="name">NAME</th>
            <th className="date">DATE</th>
            <th className="amount">AMOUNT</th>
            <th className="state">STATE</th>
            <th className="action">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {allOrders?.map((item, index) => {
            const {
              orderNumber,
              fullName,
              total,
              state,
              // amount,
              // price,
              date,
              // discount,
            } = item;
            return (
              <tr key={index}>
                <td className="order-no-body">{orderNumber}</td>
                <td className="name">{fullName}</td>
                <td className="date">{date}</td>
                <td className="amount">
                  {/* Ghc {discount > 0 ? discount : price * amount} */}
                  Ghc {total}
                </td>
                <td className="state">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      {state}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => changeStatus(orderNumber, "Processing")}
                      >
                        Processing
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => changeStatus(orderNumber, "Delivered")}
                      >
                        Delivered
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => changeStatus(orderNumber, "Cancelled")}
                      >
                        Cancelled
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td
                  className="action"
                  onClick={() => dispatch(fetchSingleOrder(item))}
                >
                  <span>{open}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th className="order-no">ORDER NO.</th>
          <th className="name">NAME</th>
          <th className="date">DATE</th>
          <th className="amount">AMOUNT</th>
          <th className="state">STATE</th>
          <th className="action">ACTION</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((item, index) => {
          const {
            orderNumber,
            fullName,
            // amount,
            // price,
            date,
            // discount,
            total,
            state,
          } = item;
          return (
            <tr key={index}>
              <td className="order-no-body">{orderNumber}</td>
              <td className="name">{fullName}</td>
              <td className="date">{date}</td>
              <td className="amount">Ghc {total}</td>
              <td className="state">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">{state}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => changeStatus(orderNumber, "Processing")}
                    >
                      Processing
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeStatus(orderNumber, "Delivered")}
                    >
                      Delivered
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeStatus(orderNumber, "Cancelled")}
                    >
                      Cancelled
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td
                className="action"
                onClick={() => dispatch(fetchSingleOrder(item))}
              >
                <span>{open}</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default OrdersList;
