import React, { useState, useEffect } from "react";
import { Search, edit, close, deleteIcon } from "../../icons";
import { AdminProduct } from "../styles/ProductAdmin.styled";
import AddNewProduct from "./AddNewProduct";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import {
  changeProductActive,
  deleteProduct,
} from "../../utils/firebaseFunctions";
import { productFields, stockFields } from "./utils";

function Products() {
  const { allProducts } = useSelector((store) => store.products);
  const [searchName, setSearchName] = useState("");
  const [searchedProduct, setSearchedProduct] = useState(allProducts);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({ id: uuidv4(), ...productFields });
  const [stock, setStock] = useState(stockFields);

  const [defaultState, setDefaultState] = useState(productFields);
  const [defaultStock, setDefaultStock] = useState(stockFields);

  if (open | isModalOpen) {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  } else {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    setDefaultState({
      id: uuidv4(),
      ...productFields,
    });
    setDefaultStock(stockFields);
  }, [product.id]);

  const handleEdit = (data) => {
    setOpen(true);
    setProduct(data);
  };
  const toggleDelete = (data) => {
    setIsModalOpen(true);
    setProduct(data);
  };

  useEffect(() => {
    const search = () => {
      if (searchName) {
        setSearchedProduct(
          allProducts?.filter((item) => {
            return (
              item.name.toLowerCase().includes(searchName.toLowerCase()) ||
              item.tags.toLowerCase().includes(searchName.toLowerCase())
            );
          })
        );
      } else {
        setSearchedProduct(allProducts);
      }
    };
    search();
  }, [searchName, allProducts]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Admin-Products";
  }, []);

  return (
    <AdminProduct style={open || isModalOpen ? { pointerEvents: "none" } : {}}>
      <div className="top">
        <div>
          <h4>Products</h4>
          <h6>Add,Delete and Manage Products</h6>
        </div>

        <div className="product-add-search">
          <button className="add-new-product" onClick={() => setOpen(true)}>
            Add New Product
          </button>
          <form className="search-product">
            <input
              type="text"
              placeholder="Search for a product"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
            <span>
              <Search />
            </span>
          </form>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="id">ID</th>
            <th className="name">Name</th>
            <th className="category">Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {searchedProduct?.map((item, index) => {
            return (
              <tr key={item.id} className={item.active ? "" : "disabled"}>
                <td className="id">{index + 1}</td>
                <td className="name">{item.name}</td>
                <td className="category">{item.category}</td>
                <td className="action">
                  <span
                    className="editIcon"
                    onClick={() => {
                      handleEdit(item);
                      setStock({
                        XS: item.XS,
                        Small: item.Small,
                        Medium: item.Medium,
                        Large: item.Large,
                        XL: item.XL,
                        XXL: item.XXL,
                      });
                    }}
                  >
                    {edit}
                  </span>
                  <span
                    className="closeIcon"
                    onClick={() => changeProductActive(item.id, !item.active)}
                  >
                    {close}
                  </span>
                  <span
                    className="deleteIcon"
                    onClick={() => toggleDelete(item)}
                  >
                    {deleteIcon}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <section
        className={
          isModalOpen ? "add-product-modal" : "add-product-modal-close"
        }
      >
        <div className="confirm-delete">
          <span className="close-modal" onClick={() => setIsModalOpen(false)}>
            {close}
          </span>
          <p>Do you want to delete {product.name}?</p>
          <div className="btns">
            <button
              onClick={() => {
                setIsModalOpen(false);
                setProduct(defaultState);
                setStock(defaultStock);
              }}
            >
              No
            </button>
            <button
              onClick={() => {
                deleteProduct(product.id);
                setProduct(defaultState);
                setStock(defaultStock);
                setIsModalOpen(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </section>
      <section
        className={open ? "add-product-modal" : "add-product-modal-close"}
      >
        <AddNewProduct
          setOpen={setOpen}
          product={product}
          setProduct={setProduct}
          defaultState={defaultState}
          defaultStock={defaultStock}
          stock={stock}
          setStock={setStock}
        />
      </section>
    </AdminProduct>
  );
}

export default Products;
