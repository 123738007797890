import styled from "styled-components";

export const CollectionSection = styled.section`
  & a {
    color: black;
  }
  .collections-container {
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .collections-items {
    margin: 0.5rem 1rem;
    position: relative;
  }
  .collections-items img {
    position: relative;
    width: 25rem;
    // height: 37.5rem;
  }
  .collection-caption {
    background-color: #efa893;
    // height: 3rem;
    width: 15rem;
    position: absolute;
    bottom: 1.5rem;
    right: 0.5rem;
  }
  .collection-caption p {
    text-align: center;
    margin-bottom: 0;
    padding: 0.6rem;
    font-weight: 500;
  }

  @media (max-width: 1730px) {
    .collections-items {
      margin: 0.5rem;
    }
    .collections-items img {
      width: 22rem;
      // height: 33rem;
    }
  }
  @media (max-width: 1480px) {
    .collections-items img {
      width: 19rem;
      // height: 28.5rem;
    }
  }
  @media (max-width: 1280px) {
    .collections-items img {
      width: 16rem;
      // height: 24rem;
    }
  }
  @media (max-width: 900px) {
    .collections-items img {
      width: 47vw;
      // height: 100%;
    }
  }
  @media (max-width: 671px) {
    .collections-items img {
      width: 45vw;
    }
    .collection-caption {
      background-color: #efa893;
      // height: 1.5rem;
      width: 10rem;
      position: absolute;
      bottom: 1.5rem;
      right: 0.5rem;
    }
    .collection-caption p {
      text-align: center;
      font-size: 0.6rem;
      margin-bottom: 0;
      padding: 0.4rem;
      font-weight: 500;
    }
  }
  @media (max-width: 500px) {
    .collection-caption {
      width: 7rem;
    }
    .collection-caption p {
      font-size: 0.4rem;
    }
  }
  @media (max-width: 320px) {
    .collections-items img {
      width: 44vw;
    }
  }
`;
