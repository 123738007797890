import React, { useState, useEffect } from "react";
import { edit, deleteIcon } from "../../icons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CouponSection } from "../styles/Admin.styled";
import {
  saveCategory,
  deleteCategory,
  saveCollection,
  deleteCollection,
} from "../../utils/firebaseFunctions";

function Categories() {
  const { categories, collections } = useSelector((store) => store.reviews);
  const [category, setCategory] = useState({ cat: "", id: uuidv4() });
  const [collection, setCollection] = useState({ coll: "", id: uuidv4() });
  const [msg, setMsg] = useState("");

  const handleCategoryChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCategory({ ...category, [name]: value });
  };
  const handleCollectionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCollection({ ...collection, [name]: value });
  };

  const handleCategorySubmit = (e) => {
    e.preventDefault();
    if (!category.cat) {
      setMsg("All fields required!");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } else {
      saveCategory(category, category.id);
      setCategory({ cat: "", id: uuidv4() });
    }
  };
  const handleCollectionSubmit = (e) => {
    e.preventDefault();
    if (!collection.coll) {
      setMsg("All fields required!");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } else {
      saveCollection(collection, collection.id);
      setCollection({ coll: "", id: uuidv4() });
    }
  };
  const handleEditCategory = (data) => {
    setCategory({ cat: data.cat, id: data.id });
  };
  const handleEditCollection = (data) => {
    setCollection({ coll: data.coll, id: data.id });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Admin-Categories";
  }, []);

  return (
    <CouponSection>
      <div className="category">
        <div className="add-coupon">
          <h1>Add new category</h1>
          <h6>{msg}</h6>
          <form onSubmit={handleCategorySubmit}>
            <input
              type="text"
              id="coupon-code"
              placeholder="Category"
              name="cat"
              value={category.cat}
              onChange={handleCategoryChange}
            />
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>

        <table>
          <thead>
            <tr>
              <th className="name">Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories?.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="name">{item.cat}</td>
                  <td className="action">
                    <span
                      className="editIcon"
                      onClick={() => handleEditCategory(item)}
                    >
                      {edit}
                    </span>
                    <span
                      className="deleteIcon"
                      onClick={() => deleteCategory(item.id)}
                    >
                      {deleteIcon}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="collection">
        <div className="add-coupon">
          <h1>Add new collection</h1>
          <h6>{msg}</h6>
          <form onSubmit={handleCollectionSubmit}>
            <input
              type="text"
              id="coupon-amount"
              placeholder="Collection"
              name="coll"
              value={collection.coll}
              onChange={handleCollectionChange}
            />
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
        <table>
          <thead>
            <tr>
              <th className="name">Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {collections?.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="name">{item.coll}</td>
                  <td className="action">
                    <span
                      className="editIcon"
                      onClick={() => handleEditCollection(item)}
                    >
                      {edit}
                    </span>
                    <span
                      className="deleteIcon"
                      onClick={() => deleteCollection(item.id)}
                    >
                      {deleteIcon}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CouponSection>
  );
}

export default Categories;
