import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchOrders } from "../features/product/productSlice";
import { db } from "../firebaseConfig";
import { OrderHistoryStyled } from "./styles/OrderHistory.Styled";

function OrderHistory() {
  const dispatch = useDispatch();
  const { orders } = useSelector((store) => store.products);
  const { user } = useSelector((store) => store.user);
  const [usersOdrers, setUsersOdrers] = useState([]);

  useEffect(() => {
    setUsersOdrers(orders.filter((item) => item.email === user?.userEmail));
  }, [orders, user?.userEmail]);

  const fetch = useCallback(async () => {
    const q = query(
      collection(db, "OrderHistory"),
      where("email", "==", user?.userEmail),
      orderBy("timestamp", "desc")
    );
    onSnapshot(q, (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      // setUsersOdrers(arr);
      dispatch(fetchOrders(arr));
    });
  }, [user, dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Order History";
  }, []);

  // if (user) {
  // }

  if (usersOdrers.length < 1) {
    return (
      <OrderHistoryStyled>
        <h1 className="text-center">You haven't placed any orders yet</h1>
      </OrderHistoryStyled>
    );
  }
  return (
    <OrderHistoryStyled>
      <div className="orders-header">
        <p className="order-num">Order Number</p>
        <p className="order-date">Date</p>
        <p className="order-payment">Payment</p>
        <p className="order-status">Status</p>
        <p className="order-total">Total</p>
      </div>
      {usersOdrers?.map((item, index) => {
        return (
          <Link to={`/order/${item.orderNumber}`} key={index}>
            <div className="order-item">
              <p className="order-num">{item.orderNumber}</p>
              <p className="order-date">{item.date}</p>
              <p className="order-payment">
                {item.payment === "Payment on site" ? "On Site" : "On Delivery"}
              </p>
              <p className="order-status">{item.state}</p>
              <p className="order-total">GHc{item.total}</p>
            </div>
          </Link>
        );
      })}
      {/* {item.orderHistory.map((item, index) => {
        return (
          <article key={index}>
            <h6>{item.name}</h6>
            <p>{item.size}</p>
            <p>{item.amount}</p>
            <p>{item.price}</p>
          </article>
        );
      })} */}
    </OrderHistoryStyled>
  );
}

export default OrderHistory;
