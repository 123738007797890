import styled from "styled-components";

export const OrderSection = styled.div`
  .details {
    position: sticky;
    top: 1rem;
    height: auto;
  }
  .special-request {
    margin: 2rem;
  }
  .special-request h2 {
    text-align: center;
    text-decoration: underline;
  }
  .customer-order-details {
    background-color: #efa893;
    margin: 2rem;
    padding: 1rem;
    width: 30rem;
    height: auto;
  }

  .customer-order-details::-webkit-scrollbar {
    display: none;
  }
  .customer-order-details h1 {
    display: inline-block;
    margin-bottom: 2rem;
  }
  .customer-order-details svg {
    height: 2rem;
    margin: 0 2rem;
    float: right;
  }
  // .admin-orders {
  //   display: flex;
  // }

  .customer-order-details table {
    margin-top: 1rem;
    margin-bottom: 3rem;
    width: 100%;
  }
  th {
    border-bottom: 1px solid white;
    height: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
  }
  td {
    height: 2rem;
    font-size: 0.9rem;
    padding-top: 1rem;
    border-bottom: 1px solid white;
  }
  tfoot td {
    height: 2rem;
    font-weight: 600;
    padding-top: 0.7rem;
    border-bottom: 0;
  }

  .product {
    width: 10rem;
  }
  .size {
    text-align: center;
    width: 5rem;
  }
  .quality {
    text-align: center;
    width: 12rem;
  }
  .price {
    width: 5rem;
  }

  .customer-details {
    margin: 2rem;
  }
  .customer-details li {
    color: white;
  }
  .customer-details span {
    color: black;
  }
  .close-btn {
    font-weight: 600;
    border: 0;
    height: 1.8rem;
    width: 5rem;
    background-color: #f5cbae;
  }
  .btn-next {
    float: right;
    font-weight: 600;
    border: 0;
    height: 1.8rem;
    width: 5rem;
    background-color: #f5cbae;
  }
  .print {
    float: right;
    font-weight: 600;
    border: 0;
    width: 0;
    background-color: transparent;
    font-size: 0.5rem;
  }
`;
