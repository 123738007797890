import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Swipers() {
  return (
    <>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <p className="ann-1">DISCOUNT END </p>
        </SwiperSlide> */}
        <SwiperSlide>
          <p className="ann-1">SHOP WEEKLY NEW ARRIVALS</p>
        </SwiperSlide>
        <SwiperSlide>
          <p className="ann-2">SHOP UNTIL 11PM WITH NEXT DAY SHIPPING</p>
        </SwiperSlide>
        <SwiperSlide>
          <p className="ann-3">
            FREE DELIVERY FOR 2 ITEMS AND ABOVE IF YOU PAY ON THE SITE
          </p>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Swipers;
