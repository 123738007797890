import styled from "styled-components";

export const MyNav = styled.section`
  #navbar {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #mobile-nav {
    display: none;
  }
  .first-nav {
    width: 98%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    height: 3rem;
    // margin-bottom: -18px;
  }
  .search-field {
    border: 1px solid #231f20;
    height: 2rem;
    width: 17rem;
    padding: 4px;
  }
  .search-btn {
    border: 1px solid #231f20;
    height: 2rem;
    padding: 0 6px;
    background-color: #231f20;
    color: white;
  }
  .main-cart {
    position: relative;
  }
  .cart-numb {
    background-color: #efa893;
    position: absolute;
    top: -5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: small;
    text-align: center;
    margin: 0;
  }
  .first-nav-links {
    list-style: none;
    display: flex;
    margin-bottom: 0;
  }
  .first-nav-links li {
    cursor: pointer;
    margin: 1rem;
  }
  .first-nav-links a {
    color: inherit;
    font: inherit;
    text-decoration: none;
  }
  .first-nav-links a:hover {
    text-decoration: none;
  }
  .second-nav-links {
    text-transform: uppercase;
    width: 98vw;
    cursor: pointer;
    height: 2.5rem;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second-nav-links a {
    color: inherit;
    font: inherit;
    text-decoration: none;
  }
  .second-nav-links li {
    margin: 1rem 1.5rem;
    font-size: 1.3rem;
  }
  .second-nav-links li:hover {
    color: #efa893;
  }
  .nav-announcement {
    width: 100%;
    // height: 4rem;
  }
  .nav-announcement p {
    margin: 0;
    font-size: 2rem;
    // padding: 0.5rem;
    // height: 4.5rem;
    color: white;
  }
  .ann-1 {
    background-color: black;
  }
  .ann-2 {
    background-color: black;
  }
  .ann-3 {
    background-color: black;
  }
  .first-announcement {
    background-color: #f5cbae;
  }
  .second-announcement {
    background-color: #efa893;
  }
  .first-announcement,
  .second-announcement {
    color: white;
    font-size: 2rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    height: 4.5rem;
    width: 100%;
  }
  #dropdown-basic:focus {
    box-shadow: none;
  }
  #dropdown-basic {
    margin: 1rem 1.5rem;
    font-size: 1.3rem !important;
    outline: none !important;
    border: none !important;
    border-radius: 0;
    background-color: transparent;
    color: inherit;
    font: inherit;
    font-weight: inherit !important;
    text-transform: none;
  }
  .dropdown-menu {
    margin-top: 0.5rem !important;
    border: 0 !important;
    border-radius: 0px !important;
    text-transform: capitalize;
  }
  .dropdown-item:hover {
    color: #efa893 !important;
  }
  @media (max-width: 1360px) {
    #navbar {
      display: none;
    }
    #mobile-nav {
      background-color: white;
      display: block;
      height: auto;
    }
    .mobile-logo {
      height: 3rem;
      margin-bottom: -8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-mobile-logo {
      height: 2.5rem;
      margin: 0 auto;
    }
    .main-mobile-nav {
      padding: 0 0.5rem;
      background-color: #f5cbae;
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .hamgurger-icon {
      height: 1.5rem;
      width: 1.8rem;
      color: black;
    }
    .mobile-nav-search {
      position: relative;
      width: 70%;
      margin: 0 0.5rem;
    }
    .mobile-nav-search svg {
      position: absolute;
      color: #000000a3;
      font-size: 1.5rem;
      top: 0.3rem;
      left: 0.5rem;
    }
    .mobile-nav-search input {
      border-radius: 8rem;
      border: 0;
      outline: 0;
      height: 2rem;
      width: 100%;
      font-size: 1rem;
      padding: 0.25rem 1rem 0.25rem 2.2rem;
    }
    .mobile-nav-search input::placeholder {
      color: black;
      font-size: 1rem;
      font-weight: lighter;
      align-self: center;
    }
    .cart {
      position: relative;
      display: flex;
      align-items: center;
      right: 5px;
    }
    .cart-icon svg {
      height: 2rem;
      width: 1.8rem;
      margin-left: 0.5rem;
    }
    .cart-icon a {
      color: black;
      text-decoration: none;
    }
    .cart-number {
      position: absolute;
      top: -3px;
      left: 24px;
      text-align: center;
      font-size: 13px;
      width: 17px;
      height: 17px;
      background-color: white;
      border-radius: 50%;
    }
    .mobile-nav-announcement p {
      margin: 0;
      font-size: 1.2rem;
    }
    .mobile-nav-announcement .ann-3 {
      font-size: 1rem;
    }
    .ann-1,
    .ann-2,
    .ann-3 {
      background-color: black;
      color: white;
    }
  }
  .hide-overlay {
    display: none;
  }
  .cart-overlay {
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
  }
  .nav-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    min-height: 100vh;
    padding: 1rem;
    z-index: 6;
    background-color: white;
    backdrop-filter: blur(8px);
    overflow-y: scroll;
  }
  .nav-sidebar::-webkit-scrollbar {
    display: none;
  }
  .hide-sidebar {
    display: none;
  }
  .mobile-nav-links {
    text-transform: uppercase;
    width: 25rem;
    list-style: none;
    padding-top: 3rem;
  }
  .close-side {
    position: absolute;
    left: 24rem;
  }
  .close-side svg {
    height: 2rem;
  }
  .mobile-nav-links li {
    cursor: pointer;
    margin: 1rem 0;
    font-size: 1.3rem;
  }
  #dropdown-basic {
    margin: 0 !important;
    padding: 0 !important;
  }
  .mobile-nav-links a {
    color: black;
    text-decoration: none;
  }
  .mobile-nav-links a:hover {
    color: black;
    text-decoration: none;
  }
  @media (max-width: 600px) {
    .mobile-nav-links {
      width: 100vw;
      background-color: white;
      padding: 1.5rem;
    }
    .close-side {
      left: 90vw;
    }
  }

  @media (max-width: 500px) {
    .mobile-nav-announcement p {
      margin: 0;
      font-size: 0.8rem;
    }
    .mobile-nav-announcement .ann-3 {
      margin: 0;
      font-size: 0.75rem;
    }
  }
`;
