import React, { useState, useEffect } from "react";
import { SingleProductImg } from "./styles/SingleProductImage";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SingleProductImages({ images }) {
  const [img, setImg] = useState("");

  useEffect(() => {
    if (images && images.length > 0) {
      setImg(images[0]);
    }
  }, [images]);

  return (
    <>
      <SingleProductImg>
        <div id="main-single-product-image">
          {images?.map((item, index) => {
            return (
              <div className="img-container" key={index}>
                <LazyLoadImage
                  src={item}
                  placeholderSrc="/images/1-min.jpg"
                  className="main-single-product-image img-fluid"
                  effect="blur"
                />
              </div>
            );
          })}
        </div>

        <div id="main-single-product-image-mobile">
          <img
            src={img}
            className="main-single-product-image img-fluid"
            alt="img"
          />
          <div className="mobile-slider">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                300: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {images?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="img-container">
                      <LazyLoadImage
                        onClick={() => setImg(item)}
                        src={item}
                        placeholderSrc="/images/1-min.jpg"
                        className="main-single-product-image img-fluid"
                        effect="blur"
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </SingleProductImg>
    </>
  );
}

export default SingleProductImages;
