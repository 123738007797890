import styled from "styled-components";

export const SingleOrderStyled = styled.section`
   {
    width: 1440px;
    min-height: 45vh;
    margin: 1rem auto;
    padding: 0.5rem 0;
  }

  .order-table {
    width: 100%;
  }
  thead {
    color: #808080;
  }
  .order-table tbody {
    border: 0;
    border-bottom: 5px solid #f5cbae;
    border-top: 5px solid #f5cbae;
  }
  .order-table tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5cbae;
    padding: 0.5rem 0;
  }
  tfoot tr {
    border-bottom: 0 !important;
  }
  .product-image {
    width: 5%;
  }
  .product-name {
    width: 50%;
    font-weight: 600;
  }
  .product-size,
  .product-qual,
  .product-price {
    width: 15%;
  }
  .order-table img {
    height: 6rem;
  }
  @media (max-width: 1460px) {
     {
      width: 95vw;
    }
    .product-image {
      width: 10%;
    }
    .product-name {
      width: 40%;
    }
    .product-size,
    .product-qual,
    .product-price {
      text-align: center;
      width: 10%;
    }
  }

  @media (max-width: 960px) {
     {
      width: 95vw;
    }
    .order-table {
      font-size: 0.7rem;
    }
    /* tfoot .product-name,
      tfoot .product-image {
        display: none;
      } */
    .product-image {
      width: 20%;
    }
    .product-name {
      width: 35%;
    }
    .product-size,
    .product-qual {
      width: 15%;
    }
    .product-price {
      width: 15%;
    }
  }
`;
