import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reviewsFetch } from "../features/reviews/reviewsSlice";
import SampleReviews from "./SampleReviews";

function Reviews() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reviewsFetch());
  }, [dispatch]);
  return (
    <>
      <SampleReviews />
    </>
  );
}

export default Reviews;
