import styled from "styled-components";

export const SampleSaleContainer = styled.section`
   {
    margin-top: 2rem;
  }
  & a {
    text-decoration: none;
  }
  .sample-sale-caption p {
    text-align: center;
    margin-bottom: 0;
    padding: 0.6rem;
    font-weight: 700;
  }
  .sample-sale-text {
    font-size: 1.4rem;
  }
  .sample-sale-text1 {
    display: none;
  }
  .sample-sale-text p {
    margin-bottom: 0;
  }
  .main-sample-sale-image img {
    box-shadow: 20px 20px #efa893;
    margin-bottom: 1.5rem;
  }
  .sample-sale-text h1 {
    font-size: 4rem;
    font-weight: 900;
    color: #efa893;
  }
  .other-samples img {
    width: 10rem;
    margin: 0.5rem;
  }
  h2 {
    font-weight: 900;
    font-size: 2.3rem;
    margin-top: 1rem;
    font-style: italic;
  }
  .sale-btn {
    color: black;
    font-size: 1.2rem;
    border: 0;
    background-color: #f5cbae;
    height: 3.5rem;
    width: 16rem;
    margin: 1rem 0;
  }

  @media (max-width: 1285px) {
    .other-samples {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    .main-sample-sale-image img {
      box-shadow: 10px 10px #efa893;
    }
    .sample-sale-text {
      font-size: 1rem;
    }
    .sample-sale-text h1 {
      margin: 0;
    }
    .sample-sale-text h2 {
      margin: 0;
    }
    .other-samples img {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .sample-sale-text h1 {
      display: none;
    }
    .sample-sale-text1 {
      font-size: 3rem;
      display: block;
    }
    .main-sample-sale-image img {
      box-shadow: 0 0;
    }
  }
`;
