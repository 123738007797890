import React, { useState } from "react";
import { ReviewFormSection } from "./styles/ReviewForm.styled";
import ReactStars from "react-rating-stars-component";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { saveReview } from "../utils/firebaseFunctions";

function ReviewForm({ productName, productId, isreviews }) {
  const [msg, setMsg] = useState("");
  const [stars, setStars] = useState(0);

  const formik = useFormik({
    initialValues: {
      title: "",
      review: "",
      name: "",
      email: "",
    },
    onSubmit: async (values, onSubmitProps) => {
      // console.log(values);
      let data = {
        id: uuidv4(),
        productName: productName,
        productId: productId,
        stars,
        title: formik.values.title,
        review: formik.values.review,
        name: formik.values.name,
        email: formik.values.email,
      };
      console.log(data);
      saveReview(data, data.id);
      setMsg("Thank you for your review");
      onSubmitProps.resetForm();
      setTimeout(() => {
        setMsg("");
      }, 3000);
    },
    validate: (values) => {
      let errors = {};
      if (!values.title) {
        errors.title = "Title is Required";
      }
      if (!values.review) {
        errors.review = "Review is Required";
      }
      if (!values.name) {
        errors.name = "Name is Required";
      }
      if (!values.email) {
        errors.email = "Email is Required";
      }
      return errors;
    },
  });

  return (
    <>
      <ReviewFormSection>
        <div className={isreviews ? "review-form" : "hide-review-form"}>
          <div className="col-md-12 text-center mt-5">
            <h5>Share your experience with us</h5>
          </div>
          <div className="col-md-12">
            <div className="msg">
              <h3 className="text-center">{msg}</h3>
            </div>
            <div className="give-stars">
              <ReactStars
                count={5}
                onChange={(newValue) => {
                  setStars(newValue);
                }}
                isHalf={true}
                size={30}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#f5cbae"
              />
            </div>
          </div>
          <div className="col-md-12">
            <form onSubmit={formik.handleSubmit}>
              <label className="required" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                id="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="err-msg">
                {formik.touched.title && formik.errors.title ? (
                  <p>{formik.errors.title}</p>
                ) : null}
              </div>
              <label className="required" htmlFor="review">
                Review
              </label>
              <textarea
                name="review"
                id="review"
                cols="50"
                rows="10"
                value={formik.values.review}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              <div className="err-msg">
                {formik.touched.review && formik.errors.review ? (
                  <p>{formik.errors.review}</p>
                ) : null}
              </div>
              <div className="form-name-email">
                <div className="name">
                  <label className="required" htmlFor="name">
                    Use your name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="err-msg">
                    {formik.touched.name && formik.errors.name ? (
                      <p>{formik.errors.name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="email">
                  <label className="required" htmlFor="email">
                    Email
                  </label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="err-msg">
                    {formik.touched.email && formik.errors.email ? (
                      <p>{formik.errors.email}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <br />
              <button type="sumbit">Post</button>
            </form>
          </div>
        </div>
      </ReviewFormSection>
    </>
  );
}

export default ReviewForm;
