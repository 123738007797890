import React from "react";
import { Link } from "react-router-dom";
import { HeroStyled } from "./styles/Hero.styled";

function Hero() {
  return (
    <HeroStyled>
      <section className="hero">
        <div className="shop-now">
          {/* <p>NEW ARRIVALS</p> */}
          <Link to="/new-arrivals">
            <button>SHOP NEW ARRIVALS</button>
          </Link>
        </div>
      </section>
    </HeroStyled>
  );
}

export default Hero;
