import styled from "styled-components";

export const ReturnPolicySection = styled.section`
  h1 {
    font-weight: 900;
    color: black;
  }
  a {
    color: black;
  }
  h3 {
    font-weight: 900;
    color: black;
  }
  p {
    font-size: 1.3rem;
    color: black;
  }
  p span {
    font-weight: 900;
    color: black;
  }
  .return-policy {
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
  .return-policy li {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    color: #efa893;
  }
  .return-policy li h3 {
    margin-top: 2rem;
  }
  .return-policy li span {
    color: black;
  }
`;
