import styled from "styled-components";

export const AlsoCheckThese = styled.section`
   {
    margin: 2.5rem 1.5rem;
  }

  & a {
    color: black;
    text-decoration: none;
  }
  & a:hover {
    text-decoration: none;
  }
  .also-check-product-items p {
    margin-bottom: 0;
  }
  .also-check-product-items {
    width: 20rem;
  }
  .also-check-product-items img {
    width: inherit;
  }
  & p {
    margin-bottom: 0;
  }
  .item-name {
    font-weight: bold;
  }
  .also-check-colors svg {
    margin: 0.25rem;
  }
  .swiper-button-next,
  .swiper-button-prev {
    /* background-color: white; */
    /* background-color: rgba(255, 255, 255, 0.5); */
    right: 10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
  }
  /*  */

  @media (max-width: 1600px) {
    .also-check-product-items {
      width: 15rem;
    }
  }
  @media (max-width: 1260px) {
    .also-check-product-items {
      width: 13rem;
    }
  }
  @media (max-width: 960px) {
    .also-check-product-items {
      width: 15rem;
    }
  }
  @media (max-width: 767px) {
    .also-check-product-items {
      width: 12rem;
    }
  }
  @media (max-width: 636px) {
    .also-check-product-items {
      width: 10rem;
    }
  }
  @media (max-width: 600px) {
    .also-check-product-items {
      width: 45vw;
    }
  }
`;
