import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  amount: 0,
  total: 0,
  isOverlayOpen: false,
  transactionRef: "",
  orderDetails: {},
  orderNumber: "",
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cartItems = [];
    },
    addToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].amount += 1;
      } else {
        const tempItem = { ...action.payload, amount: 1 };
        state.cartItems.push(tempItem);
        state.isOverlayOpen = true;
      }
    },
    removeItem: (state, action) => {
      const itemId = action.payload.id;
      const itemSize = action.payload.size;
      state.cartItems = state.cartItems.filter(
        (item) => item.size !== itemSize || item.id !== itemId
      );
    },
    increase: (state, action) => {
      const itemId = action.payload;
      const cartItem = state.cartItems.find((item) => item.id === itemId);
      cartItem.amount = cartItem.amount + 1;
    },
    decrease: (state, action) => {
      const itemId = action.payload;
      const cartItem = state.cartItems.find((item) => item.id === itemId);
      cartItem.amount = cartItem.amount - 1;
    },
    calculateTotals: (state) => {
      let amount = 0;
      let total = 0;
      state.cartItems.forEach((item) => {
        if (item.discount > 0) {
          amount += item.amount;
          total += item.amount * item.discount;
        } else {
          amount += item.amount;
          total += item.amount * item.price;
        }
      });
      state.amount = amount;
      state.total = total;
    },
    // CheckStock: (state,action) => {
    //   state.cartItems.forEach((item)=>{
    //   if(item.Small<action.payload){
    //     item.amount
    //   }
    //   })
    // },
    handleOverlay: (state) => {
      if (state.cartItems.length === 0) {
        state.isOverlayOpen = false;
      }
    },
    closeOverlay: (state) => {
      state.isOverlayOpen = false;
    },
    setTransactionId: (state, action) => {
      state.transactionRef = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setOrderNumber: (state, action) => {
      state.orderNumber = action.payload;
    },
  },
});

export const {
  // clearCart,
  // addToCart,
  // increase,
  // removeItem,
  // decrease,
  // calculateTotals,
  // handleOverlay,
  // closeOverlay,
  setTransactionId,
  setOrderDetails,
  setOrderNumber,
  // RemoveOutOfStock,
} = cartSlice.actions;

export default cartSlice.reducer;
