import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShoppingCartSection } from "../styles/Cart.styled";
import {
  removeItem,
  increase,
  decrease,
  setItemAmount,
} from "../../features/product/productSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchCoupons,
  setCouponCode,
  setIsCouponCode,
} from "../../features/reviews/reviewsSlice";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";

function ShoppingCart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems, total } = useSelector((store) => store.products);
  const { coupons } = useSelector((store) => store.reviews);

  const [coupon, setCoupon] = useState({});
  const [code, setCode] = useState("");
  const [msg, setMsg] = useState("");

  const handleCoupon = (e) => {
    e.preventDefault();
    if (coupons.length < 1) {
      try {
        const q = query(collection(db, "Coupons"));
        onSnapshot(q, (querySnapshot) => {
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push(doc.data());
          });
          dispatch(fetchCoupons(arr));
        });
      } catch (error) {}
    }

    setCoupon(coupons.find((item) => item.code === code));
  };

  useEffect(() => {
    if (coupon === undefined) {
      setMsg("The coupon code entered is not valid");
    } else if (Object.keys(coupon).length > 2) {
      setMsg(
        `Promo code "${coupon.code}" applied. You get GHc${coupon.amount} off.`
      );
      dispatch(setIsCouponCode(true));
      dispatch(setCouponCode(coupon));
    }
  }, [setMsg, coupon, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Shopping Cart";
    dispatch(setCouponCode({ code: "", amount: "0" }));
    dispatch(setIsCouponCode(false));
  }, [dispatch]);

  if (cartItems.length < 1) {
    return (
      <ShoppingCartSection>
        <div className="empty-cart" style={{ minHeight: "47vh" }}>
          <h1>Your Cart is Empty</h1>
          <button>
            <Link to="/new-arrivals">CONTINUE SHOPPING</Link>
          </button>
        </div>
      </ShoppingCartSection>
    );
  }
  return (
    <>
      <ShoppingCartSection>
        <div className="container">
          <div className="row">
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                1
              </span>
              <h3 style={{ color: "black" }}>SHOPPING CART</h3>
            </div>
            <div className="col-md-4 stages">
              <span>2</span>
              <h3>CHECKOUT</h3>
            </div>
            <div className="col-md-4 stages">
              <span>3</span>
              <h3>ORDER STATUS</h3>
            </div>
          </div>
          <section className="shopping-cart">
            <table>
              <thead>
                <tr>
                  <th className="table-product">PRODUCT</th>
                  <th>PRICE</th>
                  <th>QUANTITY</th>
                  <th>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>
                {cartItems?.map((item, index) => {
                  const { images, name, discount, price, size, amount } = item;
                  return (
                    <tr key={index}>
                      <td className="table-product">
                        <div className="your-order-item">
                          <img src={images[0]} alt="" />
                          <div className="your-order-item-info">
                            <h5>{name}</h5>
                            <p>Size {size}</p>
                            <button
                              className="remove-btn"
                              onClick={() => dispatch(removeItem(item))}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>Ghc{discount > 0 ? discount : price}</td>
                      <td>
                        <div className="item-quantity-table">
                          <p
                            onClick={() => {
                              if (amount === 1) {
                                dispatch(removeItem(item));
                                return;
                              } else {
                                dispatch(decrease(item));
                              }
                            }}
                          >
                            -
                          </p>
                          <p style={{ th: "4rem" }}>{amount}</p>
                          <p
                            onClick={() => {
                              if (
                                amount === item[size] ||
                                amount > item[size]
                              ) {
                                dispatch(setItemAmount(item));
                                return;
                              } else {
                                dispatch(increase(item));
                              }
                            }}
                          >
                            +
                          </p>
                        </div>
                      </td>
                      <td>GHc{discount > 0 ? discount : price * amount}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th className="table-product"></th>
                  <th></th>
                  <th></th>
                  <th>TOTAL: Ghc {total}</th>
                </tr>
              </tfoot>
            </table>
            <div className="row my-5">
              <h5>Enter your promotional code</h5>
              <form>
                <div className="col-md-4 promo-code">
                  <input
                    type="text"
                    placeholder="Coupon code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button type="submit" onClick={handleCoupon}>
                    OK
                  </button>
                </div>
                <p>{msg}</p>
              </form>
              <div className="col-md-4 ml-auto">
                <button
                  className="return-to-shop-btn"
                  onClick={() => navigate("/cart")}
                >
                  Return to shop
                </button>
                <button
                  className="update-cart-btn"
                  onClick={() => navigate("/checkout")}
                >
                  Proceed to checkout
                </button>
              </div>
            </div>
          </section>
          <section className="shopping-cart-mobile">
            <table>
              <thead>
                <tr>
                  <th className="table-product">PRODUCT</th>
                  <th>QUANTITY</th>
                </tr>
              </thead>
              <tbody>
                {cartItems?.map((item, index) => {
                  const { images, name, price, discount, size, amount } = item;
                  return (
                    <tr key={index}>
                      <td className="table-product">
                        <div className="your-order-item">
                          <img src={images[0]} alt="" />
                          <div className="your-order-item-info">
                            <h5>{name}</h5>
                            <p>Size {size}</p>
                            <button
                              className="remove-btn"
                              onClick={() => dispatch(removeItem(item))}
                            >
                              Remove
                            </button>
                            <p>GHc{discount > 0 ? discount : price * amount}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="item-quantity-table">
                          <p
                            onClick={() => {
                              if (amount === 1) {
                                dispatch(removeItem(item));
                                return;
                              } else {
                                dispatch(decrease(item));
                              }
                            }}
                          >
                            -
                          </p>
                          <p style={{ th: "4rem" }}>{amount}</p>
                          <p
                            onClick={() => {
                              if (
                                amount === item[size] ||
                                amount > item[size]
                              ) {
                                dispatch(setItemAmount(item));
                                return;
                              } else {
                                dispatch(increase(item));
                              }
                            }}
                          >
                            +
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mobile-total">
              <h5>TOTAL: Ghc {total}</h5>
            </div>
            <div style={{ height: "4px" }} className="order-pink-line"></div>
            <div className="row my-5 justify-content-center text-center">
              <h5>Enter your promotional code</h5>
              <br />
              <form>
                <div className="col-md-12 promo-code">
                  <input
                    type="text"
                    placeholder="Coupon code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button type="submit" onClick={handleCoupon}>
                    OK
                  </button>
                </div>
                <p>{msg}</p>
              </form>
              <div className="col-md-12">
                <button
                  className="return-to-shop-btn"
                  onClick={() => navigate("/cart")}
                >
                  Return to shop
                </button>
                <button
                  className="update-cart-btn"
                  onClick={() => navigate("/checkout")}
                >
                  Proceed to checkout
                </button>
              </div>
            </div>
          </section>
        </div>
      </ShoppingCartSection>
    </>
  );
}

export default ShoppingCart;
