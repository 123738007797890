import React, { useEffect, useState } from "react";
import { CollectionSection } from "./styles/Collection.styled";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Collections() {
  const { products } = useSelector((store) => store.products);
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    setCollection(
      products.filter(
        (item) => item.mainCollection === "true" || item.mainCollection === true
      )
    );
  }, [products]);
  return (
    <>
      <CollectionSection>
        <div className="collections-container">
          {collection?.map((item) => {
            return (
              <article key={item.id} className="collections-items">
                <Link to={`/products/${item.collection}`}>
                  <img src={item.images[0]} alt="" loading="lazy" />
                  <div className="collection-caption">
                    <p>{item.collection}</p>
                  </div>
                </Link>
              </article>
            );
          })}
        </div>
      </CollectionSection>
    </>
  );
}

export default Collections;
