import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import SharedLayout from "./components/SharedLayout";
import SingleProduct from "./components/SingleProduct";
// import Cart from "./components/cart/Cart";
import SizeChart from "./components/SizeChart";
import Reviews from "./components/Reviews";
import OrderHistory from "./components/OrderHistory";
import ReturnPolicy from "./components/ReturnPolicy";
import Dashboard from "./components/Admin/Dashboard";
import AdminSidebar from "./components/Admin/AdminSidebar";
import Orders from "./components/Admin/Orders";
import AdminProducts from "./components/Admin/Products";
import Sale from "./components/Sale";
import Products from "./components/Products";
import Categories from "./components/Admin/Categories";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import Error from "./components/Error";
import ProtectedRoute from "./components/ProtectedRoute";
import Checkout from "./components/cart/Checkout";
import OrderStatus from "./components/cart/OrderStatus";
import ShoppingCart from "./components/cart/ShoppingCart";
import ProtectedCheckout from "./components/ProtectedCheckout";
import ProtectedOrderStatus from "./components/ProtectedOrderStatus";
import Coupons from "./components/Admin/Coupons";
import SearchedItem from "./components/SearchedProducts";
import NewArrivals from "./components/NewArrivals";
import MakeARetrurn from "./components/MakeARetrurn";
import AboutUs from "./components/AboutUs";
import SingleOrder from "./components/SingleOrder";

function Routers() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route path="/product/:id" element={<SingleProduct />} />
            <Route path="new-arrivals" element={<NewArrivals />} />
            <Route path="sale" element={<Sale />} />
            <Route path="products/:cat" element={<Products />} />
            <Route path="products/search/:val" element={<SearchedItem />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route
              path="/checkout"
              element={
                <ProtectedCheckout>
                  <Checkout />
                </ProtectedCheckout>
              }
            />
            <Route
              path="/order-status"
              element={
                <ProtectedOrderStatus>
                  <OrderStatus />
                </ProtectedOrderStatus>
              }
            />
            <Route path="/create-account" element={<SignUp />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/size-chart" element={<SizeChart />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/order/:id" element={<SingleOrder />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
            <Route path="/return-process" element={<MakeARetrurn />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="*" element={<Error />} />
          </Route>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminSidebar />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="/admin/orders" element={<Orders />} />
            <Route path="/admin/products" element={<AdminProducts />} />
            <Route path="/admin/categories" element={<Categories />} />
            <Route path="/admin/coupons" element={<Coupons />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routers;
