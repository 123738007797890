import styled from "styled-components";

export const RegisterSection = styled.section`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 47vh;
  }
  .register-form {
    text-align: center;
    color: #efa893;
    width: 23rem;
    // margin: 1rem auto;
  }
  .register-form input {
    border: 1px solid #efa893;
    height: 2.5rem;
    width: 20rem;
    // margin: 1rem auto;
    padding: 0.5rem;
  }
  .register-form input::placeholder {
    color: rgba(119, 114, 114, 0.726);
    font-size: 1.3rem;
  }
  .register-form h6 {
    margin: 1rem 0;
  }
  .register-form a {
    color: inherit;
  }
  .register-form .submit-btn {
    border: 0;
    font-size: larger;
    background-color: #efa893;
    color: white;
    height: 3rem;
    width: 10rem;
  }
  .err-msg {
    height: 2rem;
    color: rgb(255, 45, 45);
  }
  .err-msg p {
    margin: 0;
  }
`;
