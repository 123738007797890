import styled from "styled-components";

export const AdminDatedSection = styled.form`
   {
    margin: 2rem;
    box-shadow: 1px 1px 20px 1px rgb(151, 151, 151);
    display: inline-block;
    z-index: 1;
  }
  #day,
  #year,
  #month {
    padding: 0.5rem;
    border: 0;
    outline: 0;
  }
  #day {
    height: 3.5rem;
    width: 6rem;
  }
  #month {
    background-color: #f5cbae;
    height: 3.5rem;
    width: 8rem;
  }
  #year {
    height: 3.5rem;
    width: 6rem;
  }
`;
