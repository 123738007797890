import styled from "styled-components";

export const ProductContainerSection = styled.section`
   {
    margin: 2.5rem 1.5rem;
  }
  & a {
    color: black;
    text-decoration: none;
  }
  & a:hover {
    text-decoration: none;
  }

  .product-items {
    margin: 0rem 0.5rem;
  }
  img-container,
  .product-items img {
    width: 320px;
    height: 464px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    /* background-color: white; */
    /* background-color: rgba(255, 255, 255, 0.5); */
    right: 10px;
    padding: 30px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
  }
  .caption .name p {
    margin-bottom: 0;
    font-weight: bold;
  }
  .caption .price {
    margin-bottom: 0;
    margin-top: 6px;
  }
  .product-price span {
    opacity: 0.7;
    text-decoration: line-through;
  }

  @media (max-width: 1650px) {
    img-container,
    .product-items img {
      width: 240px;
      height: 348px;
    }
  }
  @media (max-width: 1250px) {
    img-container,
    .product-items img {
      width: 184px;
      height: 266px;
    }
  }
  @media (max-width: 767px) {
    img-container,
    .product-items img {
      width: 20rem;
      height: 464px;
    }
  }
  @media (max-width: 700px) {
    img-container,
    .product-items img {
      width: 280px;
      height: 406px;
    }
  }
  @media (max-width: 620px) {
    img-container,
    .product-items img {
      width: 224px;
      height: 324px;
    }
  }
  @media (max-width: 520px) {
    .caption p {
      font-size: 0.8rem;
    }
    img-container,
    .product-items img {
      width: 192px;
      height: 278px;
    }
  }
  @media (max-width: 440px) {
    img-container,
    .product-items img {
      width: 160px;
      height: 232px;
    }
    .caption p {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 366px) {
    img-container,
    .product-items img {
      width: 128px;
      height: 185px;
    }
    .caption p {
      font-size: 0.6rem;
    }
  }
`;
