import React from "react";
import { MyLoader } from "./styles/Loader.styled";

function Loader() {
  return (
    <MyLoader>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </MyLoader>
  );
}

export default Loader;
