import React, { useState, useEffect } from "react";
import { SampleSaleContainer } from "./styles/SampleSale.styled";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SampleSaleSwiper from "./SampleSaleSwiper";
import MSampleSaleSwiper from "./MSampleSaleSwiper";
import { HiOutlineArrowRight } from "react-icons/hi";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SampleSale() {
  const { products } = useSelector((store) => store.products);
  const [mainSale, setMainSale] = useState([]);

  useEffect(() => {
    setMainSale(
      products.filter(
        (item) => item.mainSale === true || item.mainSale === "true"
      )
    );
  }, [products]);

  return (
    <>
      <SampleSaleContainer>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-5">
              <div className="main-sample-sale-image">
                <Link to="/sale">
                  <h1
                    style={{ color: "#efa893" }}
                    className="sample-sale-text1"
                  >
                    Sale
                  </h1>
                </Link>
                {mainSale.length > 0 ? (
                  <Link to="/sale">
                    <LazyLoadImage
                      src={mainSale[0].images[0]}
                      className="img-fluid"
                      alt="sample sale"
                      placeholderSrc="/images/1-min.jpg"
                      effect="blur"
                    />
                  </Link>
                ) : (
                  ""
                )}
              </div>

              <MSampleSaleSwiper />
            </div>
            <div
              className="col-md-6 mx-auto d-flex flex-column justify-content-between
"
            >
              <div className="sample-sale-text">
                <Link to="/sale">
                  <h1>Sale</h1>
                </Link>
                <h2>We love a good bargain!</h2>
                <p>
                  These pieces include products used during our shoots which
                  have been worn by our models once or twice in great condition
                  and if otherwise, it will be stated in the description of the
                  product.
                  <br />
                  <br />
                  It also include products that have been reduced to clear off
                  our shelves to make way for new arrivals.
                  <br />
                  <br />
                  Terms and conditions apply for all products on sale.
                </p>
                <Link to="/sale">
                  <button className="sale-btn">
                    Shop products on sale <HiOutlineArrowRight />
                  </button>
                </Link>
              </div>

              <SampleSaleSwiper />
            </div>
          </div>
        </div>
      </SampleSaleContainer>
    </>
  );
}

export default SampleSale;
