import React, { useEffect } from "react";
import { ReturnPolicySection } from "./styles/ReturnPolicy.styled";

function ReturnPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Return Policy";
  }, []);
  return (
    <>
      <ReturnPolicySection>
        <div className="container ">
          <div className="row">
            <div className="col-md-12 mt-3 text-center">
              <h1 className="">Return Policy</h1>
            </div>
          </div>
        </div>
        <div className="container return-policy">
          <div className="row">
            <div className="col-md-12">
              <p>
                At LMK, customer satisfaction is always our top priority. Our
                dedicated fulfilment team inspects your order prior to shipping.
                Our product quality is guaranteed. However, on the rare
                occasions where you’re unsatisfied with any of our products, we
                encourage you to return it for an exchange. Please contact us
                within 7 days of receiving it for an exchange.
              </p>
              <br />
              <h3>Returnable Items</h3>
              <p>
                Items that can be returned for an exchange must follow the
                criteria below:
              </p>
              <ul>
                <li>
                  <span>
                    Faulty items damaged/broken, or soiled upon arrival
                  </span>
                </li>
                <li>
                  <span>
                    Items received in the incorrect size or color. (Double check
                    size selected at checkout)
                  </span>
                </li>
                <li>
                  <span>Unwashed, unworn, and unused items.</span>
                </li>
              </ul>

              <p>
                <span>Note: </span>Returned items must be in unused condition
                with original packaging. We will not accept items that do not
                meet the above criteria.
              </p>
              <br />
              <h3>Non-Returnable Items</h3>
              <p>We will not accept returns in the following conditions:</p>
              <ul>
                <li>
                  <span>Items outside the 7 days waranty time frame.</span>
                </li>
                <li>
                  <span>Washed, worn, used or misused items.</span>
                </li>
                <li>
                  <span>Items under sale category.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ReturnPolicySection>
    </>
  );
}

export default ReturnPolicy;
