import styled from "styled-components";

export const AdminSection = styled.section`
  .admin-sidebar {
    display: flex;
    z-index: 2;
    position: fixed;
    min-height: 100vh;
    background-color: #efa893;
    width: 20rem;
    display: flex;
    left: 0;
    flex-direction: column;
    align-items: center;
    transition: 0.5s ease-in-out;
  }
  .hide-admin-sidebar {
    z-index: 2;
    left: -20rem;
    display: flex;
    position: fixed;
    min-height: 100vh;
    background-color: #efa893;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s ease-in-out;
  }
  .hamburger {
    z-index: 1;
    cursor: pointer;
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
  }
  .close {
    z-index: 3;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 4px;
  }
  .close svg,
  .hamburger svg {
    width: 2rem;
    height: 2rem;
  }
  .logo {
    height: 3rem;
    margin: 3rem auto;
  }

  .dashboard-links {
    list-style: none;
    width: 100%;
    padding: 0 !important;
  }
  .dashboard-links a {
    text-decoration: none;
  }

  .dashboard-links li {
    position: relative;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    padding: 0.8rem;
    font-size: 30px;
    color: white;
  }

  .dashboard-links li:hover {
    background-color: #f5cbae;
  }
  .dashboard-links svg {
    position: absolute;
    right: 3rem;
    height: 2.2rem;
  }
  // .admin-section {
  //   overflow: hidden;
  // }
  .profile {
    text-transform: capitalize;
  }
  .dashboard {
    width: 1440px;
    margin: 2rem auto;
  }
  .stats {
    display: flex;
  }
  .stats-card {
    position: relative;
    margin: 0 2rem;
    padding: 1rem;
    width: 20rem;
    height: 7rem;
    border-bottom: 10px solid #f5cbae;
    box-shadow: 5px 5px 30px 5px rgb(180, 180, 180);
  }
  .stats-card h1 {
    margin-bottom: 0;
  }
  .stats-card small {
    font-size: 1.5rem;
  }
  .stats-card svg {
    height: 1.5rem;
  }
  .more-icon svg {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
  .profile {
    color: #efa893;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2rem 10rem 2rem 0;
  }
  .profile-icon {
    position: relative;
    background-color: #efa893;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    left: -0.5rem;
  }
`;

export const CouponSection = styled.section`
   {
    width: 1440px;
    margin: 1rem auto;
  }
  .category {
    width: 50%;
  }
  .collection {
    width: 50%;
  }
  form {
    width: 20rem;
    margin-top: 2rem;
  }
  label {
    font-size: 1.2rem;
  }
  #coupon-code,
  #coupon-amount {
    display: block;
    width: 20rem;
    height: 2.5rem;
    border: 1px solid #efa893;
    margin: 0.5rem;
    padding: 4px;
  }
  .submit-btn {
    background-color: #efa893;
    color: white;
    border: 0;
    width: 6rem;
    height: 2.5rem;
    margin: 0.5rem;
  }
  table {
    margin-top: 2rem;
    width: 40%;
  }
  tr {
    border-bottom: 1px solid rgba(83, 83, 83, 0.192);
  }

  .action {
    display: flex;
  }
  .action svg {
    cursor: pointer;
    height: 1.5rem;
    margin-right: 4px;
    border-radius: 4px;
  }
  .editIcon svg {
    color: rgb(15, 15, 163);
    border: 2px solid rgb(15, 15, 163);
  }
  .deleteIcon svg {
    color: red;
    border: 2px solid red;
  }
`;
