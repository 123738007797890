import React from "react";
import { Outlet } from "react-router-dom";
import NavbarComp from "./NavbarComp";
import styled from "styled-components";
import Footer from "./Footer";

export const MainSec = styled.section`
   {
    margin-top: 8.7rem;
  }

  @media (max-width: 1360px) {
     {
      margin-top: 7.3rem;
    }
  }
  @media (max-width: 500px) {
     {
      margin-top: 6.7rem;
    }
  }
  @media (max-width: 367px) {
     {
      margin-top: 8rem;
    }
  }
`;

function SharedLayout() {
  return (
    <>
      <NavbarComp />
      <MainSec>
        <Outlet />
      </MainSec>
      <Footer />
    </>
  );
}

export default SharedLayout;
