import styled from "styled-components";

export const ProductSection = styled.section`
   {
    min-height: 47vh;
  }
  .products {
    min-height: 45vh;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  & a {
    color: black;
    text-decoration: none;
  }
  & a:hover {
    color: black;
    text-decoration: none;
  }
  .empty-category {
    font-family: TestFoundersGrotesk-Regular, sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    color: black;
    display: flex;
    height: 25vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product-item {
    width: 23rem;
    margin: 1rem;
  }
  img-container,
  .product-item img {
    width: 319px;
    height: 464px;
  }
  .product-item p {
    margin-bottom: 0;
  }
  .product-price {
    margin-top: 6px;
    font-weight: bolder;
  }
  .product-price span {
    opacity: 0.7;
    text-decoration: line-through;
  }
  .load-more {
    border: 0;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    background-color: #efa893;
    color: black;
    height: 3rem;
    width: 14rem;
  }
  @media (max-width: 1810px) {
     {
      // width: 100vw;
      margin: 1rem auto;

      margin-top: 16.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
    }
    .products {
      // width: 100vw;
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  @media (max-width: 1620px) {
     {
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .product-item {
      width: 20rem;
      margin: 0.5rem;
    }
    img-container,
    .product-item img {
      height: 400px;
      width: 275px;
    }
  }
  @media (max-width: 1055px) {
    .product-item {
      width: 15rem;
    }
    img-container,
    .product-item img {
      height: 296px;
      width: 204px;
    }
  }
  @media (max-width: 815px) {
    .product-item {
      width: 13rem;
    }
    .product-item p {
      font-size: 0.7rem;
    }
    img-container,
    .product-item img {
      width: 170px;
      height: 248px;
    }
  }
  @media (max-width: 720px) {
    .product-item {
      width: 15rem;
    }
    img-container,
    .product-item img {
      width: 204px;
      height: 296px;
    }
  }
  @media (max-width: 550px) {
    .product-item {
      width: 13rem;
    }
    img-container,
    .product-item img {
      height: 264px;
      width: 182px;
    }
    .product-item p {
      font-size: 0.6rem;
    }
  }
  @media (max-width: 480px) {
    .product-item {
      width: 10rem;
    }
    img-container,
    .product-item img {
      height: 208px;
      width: 143px;
    }
  }
  @media (max-width: 384px) {
    .product-item {
      width: 9rem;
      margin: 1rem;
    }
    img-container,
    .product-item img {
      height: 184px;
      width: 126px;
    }
  }
  @media (max-width: 352px) {
    .product-item {
      width: 8rem;
      margin: 1rem;
    }
    img-container,
    .product-item img {
      height: 192px;
      width: 132px;
    }
  }
  @media (max-width: 338px) {
    .product-item {
      width: 7rem;
      margin: 1rem;
    }
    img-container,
    .product-item img {
      height: 168px;
      width: 115px;
    }
  }
`;
