import React, { useEffect } from "react";
import { OrderStatusSection } from "../styles/Cart.styled";
import { useDispatch, useSelector } from "react-redux";
import { setCouponCode } from "../../features/reviews/reviewsSlice";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const date = new Date();

function OrderStatus() {
  const { orderDetails, orderNumber } = useSelector((store) => store.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Order Status";
    dispatch(setCouponCode(""));
  }, [dispatch]);
  return (
    <>
      <OrderStatusSection>
        <div className="container">
          <div className="row">
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                1
              </span>
              <h3 style={{ color: "black" }}>SHOPPING CART</h3>
            </div>
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                2
              </span>
              <h3 style={{ color: "black" }}>CHECKOUT</h3>
            </div>
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                3
              </span>
              <h3 style={{ color: "black" }}>ORDER STATUS</h3>
            </div>
          </div>
          <section className="order-status">
            <ul>
              <li>
                <span>
                  Name:<strong>{orderDetails.fullName} </strong>
                </span>
              </li>
              <li>
                <span>
                  Number:<strong>{orderDetails.phone} </strong>
                </span>
              </li>
              <li>
                <span>
                  City/Town:<strong>{orderDetails.city} </strong>
                </span>
              </li>
              <li>
                <span>
                  Order number:<strong> {orderNumber}</strong>
                </span>
              </li>
              <li>
                <span>
                  Date:
                  <strong>
                    {`${date.getDate()} ${
                      months[date.getMonth()]
                    }, ${date.getFullYear()}`}
                  </strong>
                </span>
              </li>
              <li>
                <span>
                  Total:<strong> Ghc {orderDetails.subTotal}</strong>
                </span>
              </li>
              <li>
                <span>
                  Payment Method:<strong> {orderDetails.payment}</strong>
                </span>
              </li>
            </ul>
            <h3 className="text-center">Thank you for shopping with LMK.</h3>
          </section>
        </div>
      </OrderStatusSection>
    </>
  );
}

export default OrderStatus;
