import styled from "styled-components";

export const ReviewFormSection = styled.section`
  .review-form {
    position: relative;
    width: 100%;
    margin: 4rem auto;
    z-index: 1;
    height: 30rem;
    transition: 1s ease-out;
  }
  .hide-review-form {
    position: relative;
    z-index: -1;
    transform: translateY(-50rem);
    display: none;
    height: 0;
    transition: 1s ease-out;
  }
  .give-stars {
    color: #f5cbae;
  }
  .hide-review-form input,
  .review-form input {
    padding: 4px;
    border-radius: 0;
    border: 1px solid black;
  }
  #title {
    width: 100%;
  }
  #review {
    border-radius: 0;
    border: 1px solid black;
    width: 100%;
    padding: 4px;
    height: 3rem;
  }
  #name {
    width: 99%;
  }
  #email {
    width: 100%;
  }
  .name,
  .email {
    width: 50%;
    font-size: 16px !important;
  }
  .form-name-email {
    width: 100%;
    display: flex;
  }
  .required::before {
    content: "*";
    padding-left: 0.2rem;
    color: #efa893;
  }
  .hide-review-form button,
  .review-form button {
    width: 5rem;
    height: 2rem;
    background-color: #efa893;
    color: black;
    border: 0;
  }
  .msg {
    height: 2rem;
    color: rgb(255, 45, 45);
  }
  .msg p {
    margin: 0;
  }
  .err-msg {
    height: 2rem;
    color: rgb(255, 45, 45);
  }
  .err-msg p {
    margin: 0;
  }
  @media (max-width: 1200px) {
    .review-form {
      height: 34rem;
    }
    .form-name-email {
      width: 100%;
      display: block;
    }
    #name {
      width: 100%;
    }
    #email {
      width: 100%;
    }
    .name,
    .email {
      width: 100%;
    }
    .review-form button {
      width: 7rem;
    }
  }
`;
