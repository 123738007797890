import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductSection } from "../components/styles/Products.styled";
import PinkBar from "./PinkBar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

function NewArrivals() {
  const { newArrivals, isLoading, filterValue } = useSelector(
    (store) => store.products
  );

  const [limit, setLimit] = useState(12);
  const [items, setItems] = useState([...newArrivals]);

  useEffect(() => {
    setItems([...newArrivals]);
    setItems(
      [...newArrivals]?.sort((a, b) => {
        switch (filterValue) {
          case "low to high":
            return a.price - b.price;
          case "high to low":
            return b.price - a.price;
          case "latest":
            return (a = b);
          default:
            return (a = b);
        }
      })
    );
  }, [newArrivals, filterValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | New Arrivals";
    setLimit(12);
  }, []);

  if (isLoading) {
    return (
      <section
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
          return (
            <article key={item} className="m-3">
              <Skeleton height={450} width={400} />
              <Skeleton height={30} width={400} />
              <Skeleton height={30} width={150} className="mt-3" />
            </article>
          );
        })}
      </section>
    );
  }

  return (
    <>
      <ProductSection>
        <h2
          style={{
            textAlign: "center",
            fontStyle: "italic",
            textTransform: "uppercase",
          }}
        >
          New Arrivals
        </h2>
        <PinkBar />
        <section className="products">
          {items?.slice(0, limit).map((item) => {
            const { name, id, images, price, discount } = item;
            return (
              <Link key={id} to={`/product/${id}`}>
                <article className="product-item">
                  <div className="img-container">
                    <LazyLoadImage
                      src={images[0]}
                      placeholderSrc="/images/1-min.jpg"
                      alt={name}
                      effect="blur"
                    />
                  </div>
                  <div className="product-item-footer">
                    <p className="product-name">{name}</p>

                    {discount > 0 ? (
                      <p className="product-price">
                        <span>Ghc {price}</span> GHc {discount}
                      </p>
                    ) : (
                      <p className="product-price">Ghc {price}</p>
                    )}
                  </div>
                </article>
              </Link>
            );
          })}
        </section>
        {limit < newArrivals.length ? (
          <button className="load-more" onClick={() => setLimit(limit + 12)}>
            Load more products
          </button>
        ) : (
          ""
        )}
      </ProductSection>
    </>
  );
}

export default NewArrivals;
