import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { SingleOrderStyled } from "./styles/SingleOrder.styled";

function SingleOrder() {
  const { id } = useParams();
  const { orders } = useSelector((store) => store.products);
  const { user } = useSelector((store) => store.user);
  const [order, SetOrder] = useState({});
  useEffect(() => {
    SetOrder(
      orders.find(
        (item) => item.orderNumber === id && item.email === user?.userEmail
      )
    );
  }, [orders, user?.userEmail, id]);

  if (!order) {
    return <Navigate to="/order-history" />;
  }
  const { orderHistory } = order;
  return (
    <SingleOrderStyled>
      <table className="order-table">
        <thead>
          <tr>
            <th className="product-image"></th>
            <th className="product-name">Product</th>
            <th className="product-size">Size</th>
            <th className="product-price">Price</th>
            <th className="product-qual">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {orderHistory?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="product-image">
                  <img src={item.images[0]} alt="" />
                </td>
                <td className="product-name">{item.name}</td>
                <td className="product-size">{item.size}</td>
                <td className="product-price">GHc{item.price}</td>
                <td className="product-qual">{item.amount}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {order.deliveryFee > 0 ? (
            <tr>
              <td className="product-image"></td>
              <td className="product-name"></td>
              <td className="product-qual"></td>
              <th className="product-size">Delivery</th>
              <th className="product-price">-GHc{order.deliveryFee}</th>
            </tr>
          ) : (
            ""
          )}
          {order.CouponAmount > 0 ? (
            <tr>
              <td className="product-image"></td>
              <td className="product-name"></td>
              <td className="product-qual"></td>
              <th className="product-size">Discount</th>
              <th className="product-price">GHc{order.CouponAmount}</th>
            </tr>
          ) : (
            ""
          )}
          <tr>
            <td className="product-image"></td>
            <td className="product-name"></td>
            <td className="product-qual"></td>
            <th className="product-size">Total</th>
            <th className="product-price">GHc{order.total}</th>
          </tr>
        </tfoot>
      </table>
    </SingleOrderStyled>
  );
}

export default SingleOrder;
