import styled from "styled-components";

export const OrderHistoryStyled = styled.section`
   {
    width: 1440px;
    min-height: 45vh;
    margin: 1rem auto;
    padding: 0.5rem 0;
  }
  & a {
    color: black;
    text-decoration: none;
  }
  .orders-header,
  .order-item {
    background-color: aliceblue;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    margin: 1rem 0;
    padding: 1rem;
    transition: 0.3s ease-in-out;
  }

  .order-num,
  .order-date,
  .order-payment,
  .order-status,
  .order-total {
    width: 20%;
  }

  .orders-header p {
    margin: 0;
    padding: 0 1rem;
    font-weight: 700;
  }
  .order-item:hover {
    transition: 0.3s ease-in-out;
    transform: translate(0px, -5px);
  }
  .order-item:active {
    transition: 0s;
    box-shadow: inset 2px 1px 5px black;
  }
  .order-item p {
    margin: 0;
    padding: 0 1rem;
  }
  @media (max-width: 1480px) {
     {
      width: 95vw;
    }
    .orders-header,
    .order-item {
      width: 95vw;
    }
  }
  @media (max-width: 930px) {
    .order-payment {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .orders-header,
    .order-item {
      padding: 0;
    }
    .order-num,
    .order-date {
      width: 30%;
      font-size: 0.8rem;
    }
    .order-status,
    .order-total {
      width: 20%;
      font-size: 0.8rem;
    }
  }
  @media (max-width: 510px) {
    .order-num {
      width: 30%;
    }
    .order-date {
      width: 30%;
    }
    .order-status {
      width: 20%;
      font-size: 0.8rem;
    }
    .order-total {
      width: 20%;
      font-size: 0.8rem;
    }
  }
  @media (max-width: 390px) {
    .order-num {
      width: 30%;
    }
    .order-date {
      width: 30%;
    }
    .order-status {
      width: 20%;
      font-size: 0.8rem;
    }
    .order-total {
      width: 20%;
      font-size: 0.8rem;
    }
  }
  @media (max-width: 390px) {
    .order-num {
      width: 30%;
    }
    .order-date {
      width: 30%;
    }
    .order-status {
      width: 20%;
      font-size: 0.8rem;
    }
    .order-total {
      width: 20%;
      font-size: 0.8rem;
    }
  }
  @media (max-width: 350px) {
    .order-total {
      display: none;
    }
    .order-num {
      width: 35%;
    }
    .order-date {
      width: 35%;
    }
    .order-status {
      width: 30%;
      font-size: 0.8rem;
    }
  }
`;
