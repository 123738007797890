import axios from "axios";
import { doc, increment, setDoc, updateDoc } from "firebase/firestore";
import {
  setOrderDetails,
  setOrderNumber,
  setTransactionId,
} from "../features/cart/cartSlice";
import { db } from "../firebaseConfig";
import { addOrderCount, saveNewsLetter, saveOrder } from "./firebaseFunctions";

const d = new Date();
const orderNum = `${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getDay()}${d.getDate()}22`;
export const handlePaymentOnSite = async (
  cartItems,
  formik,
  transaction,
  dispatch,
  total,
  deliveryFee,
  couponCode,
  user
) => {
  dispatch(setTransactionId(transaction.reference));
  const saveProduct = async () => {
    cartItems.forEach(async (item) => {
      await updateDoc(doc(db, "Products", item.id), {
        [item.size]: increment(-item.amount),
      });
    });
  };
  saveProduct();
  addOrderCount();
  const newsLetter = {
    phone: formik.values.phone,
    email: formik.values.email,
  };

  saveNewsLetter(newsLetter);

  const details = {
    ...formik.values,
    state: "Processing",
    TransactionId: transaction.reference,
    subTotal: total,
    total: total + deliveryFee - couponCode.amount,
    deliveryFee,
    couponCode: couponCode.code,
    CouponAmount: couponCode.amount,
    orderNumber: `${formik.values.fullName.slice(0, 3)}${orderNum}`,
    orderHistory: cartItems,
  };

  dispatch(setOrderDetails(details));
  saveOrder(details, details.orderNumber);
  dispatch(setOrderNumber(details.orderNumber));

  await setDoc(
    doc(db, "Users", user.userEmail),
    {
      fullName: formik.values.fullName,
      region: formik.values.region,
      city: formik.values.city,
      phone: formik.values.phone,
    },
    {
      merge: true,
    }
  );
};

export const handlePaymentOnDelivery = async (
  cartItems,
  formik,
  dispatch,
  total,
  deliveryFee,
  couponCode,
  user
) => {
  const saveProduct = async () => {
    cartItems.forEach(async (item) => {
      await updateDoc(doc(db, "Products", item.id), {
        [item.size]: increment(-parseInt(item.amount)),
      });
    });
  };
  saveProduct();
  addOrderCount();
  const newsLetter = {
    phone: formik.values.phone,
    email: formik.values.email,
  };

  saveNewsLetter(newsLetter);
  const details = {
    ...formik.values,
    state: "Processing",
    subTotal: total,
    total: total + deliveryFee - couponCode.amount,
    deliveryFee,
    couponCode: couponCode.code,
    CouponAmount: couponCode.amount,
    orderNumber: `${formik.values.fullName.slice(0, 3)}${orderNum}`,
    orderHistory: cartItems,
  };
  const datas = {
    sender: "Lomokie",
    message: `Order ${formik.values.fullName.slice(
      0,
      3
    )}${orderNum} has been placed successfully. Thank you for shopping with LMK.`,
    recipients: [`${formik.values.phone}`],
  };

  const config = {
    method: "post",
    url: "https://sms.arkesel.com/api/v2/sms/send",
    headers: {
      "api-key": "OmtzRWl0eTFhdTg3MEtFTVA=",
    },
    data: datas,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  dispatch(setOrderDetails(details));
  saveOrder(details, details.orderNumber);
  dispatch(setOrderNumber(details.orderNumber));

  await setDoc(
    doc(db, "Users", user.userEmail),
    {
      fullName: formik.values.fullName,
      region: formik.values.region,
      city: formik.values.city,
      phone: formik.values.phone,
    },
    {
      merge: true,
    }
  );
};

export const checkoutSms = (formik) => {
  const data = {
    sender: "Lomokie",
    message: `Order ${formik.values.fullName.slice(
      0,
      3
    )}${orderNum} has been placed successfully. Thank you for shopping with LMK.`,
    recipients: [`${formik.values.phone}`],
  };

  const config = {
    method: "post",
    url: "https://sms.arkesel.com/api/v2/sms/send",
    headers: {
      "api-key": "OmtzRWl0eTFhdTg3MEtFTVA=",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
