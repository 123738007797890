import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AlsoCheckThese } from "./styles/AlsoCheckTheseOut.styled";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper";

function AlsoCheckTheseOut({ paramsID }) {
  const { products } = useSelector((store) => store.products);
  let items = products.filter((item) => item.id !== paramsID);

  function shuffleArray(array) {
    let len = array.length,
      currentIndex;
    for (currentIndex = len - 1; currentIndex > 0; currentIndex--) {
      let randIndex = Math.floor(Math.random() * (currentIndex + 1));
      let temp = array[currentIndex];
      array[currentIndex] = array[randIndex];
      array[randIndex] = temp;
    }
  }
  shuffleArray(items);

  return (
    <>
      <AlsoCheckThese className="also-check-these-out">
        <h5 className="text-center">Also Check these out...</h5>
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              800: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1000: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {items?.slice(0, 20).map((item) => {
              const { name, id, images, price } = item;
              return (
                <SwiperSlide key={id}>
                  <Link to={`/product/${id}`}>
                    <article className="also-check-product-items">
                      <img src={images[0]} alt="" />
                      <p className="item-name">{name}</p>
                      <p className="price">Ghc {price}</p>
                    </article>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      </AlsoCheckThese>
    </>
  );
}

export default AlsoCheckTheseOut;
