import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name Required"),
  lastName: yup.string().required("Last Name Required"),
  email: yup
    .string()
    .email("Please Enter Valid Email")
    .required("Email Required"),
  password: yup.string().min(8).required("Password Required"),
});

// validate: (values) => {
//   let errors = {};

//   if (!values.firstName) {
//     errors.firstName = "Required";
//   }
//   if (!values.lastName) {
//     errors.lastName = "Required";
//   }
//   if (!values.email) {
//     errors.email = "Required";
//   } else if (
//     !/^[A-Z0-9.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
//   ) {
//     errors.email = "Please Enter Valid Email";
//   }
//   if (!values.password) {
//     errors.password = "Required";
//   }

//   return errors;
// }
