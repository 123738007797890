import React from "react";

function BillingForm({ formik }) {
  return (
    <>
      <div className="name">
        <label htmlFor="name" className="required">
          Full Name
        </label>
        <input
          type="text"
          id="name"
          name="fullName"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="err-msg">
        {formik.touched.fullName && formik.errors.fullName ? (
          <p>{formik.errors.fullName}</p>
        ) : null}
      </div>
      <div className="region-city">
        <div className="region">
          <label htmlFor="region" className="required">
            Region:
          </label>
          <select
            id="region"
            name="region"
            value={formik.values.region}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Select your region</option>
            <option value="Ahafo Region">Ahafo Region</option>
            <option value="Ashanti Region">Ashanti Region</option>
            <option value="Bono East Region">Bono East Region</option>
            <option value="Bono Region">Bono Region</option>
            <option value="Central Region">Central Region</option>
            <option value="Eastern Region">Eastern Region</option>
            <option value="Greater Accra Region">Greater Accra Region</option>
            <option value="North East Region">North East Region</option>
            <option value="Northern Region">Northern Region</option>
            <option value="Oti Region">Oti Region</option>
            <option value="Savannah Region">Savannah Region</option>
            <option value="Upper East Region">Upper East Region</option>
            <option value="Upper West Region">Upper West Region</option>
            <option value="Volta Region">Volta Region</option>
            <option value="Western North Region">Western North Region</option>
            <option value="Western Region">Western Region</option>
          </select>
          <div className="err-msg">
            {formik.touched.region && formik.errors.region ? (
              <p>{formik.errors.region}</p>
            ) : null}
          </div>
        </div>
        <div className="city">
          <label htmlFor="city" className="required">
            City/Town
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="err-msg">
            {formik.touched.city && formik.errors.city ? (
              <p>{formik.errors.city}</p>
            ) : null}
          </div>
        </div>
      </div>

      <div className="phone-email">
        <div className="phone">
          <label htmlFor="phone" className="required">
            Phone Number
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="err-msg">
            {formik.touched.phone && formik.errors.phone ? (
              <p>{formik.errors.phone}</p>
            ) : null}
          </div>
        </div>
        <div className="email">
          <label htmlFor="email" className="required">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="err-msg">
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>
        </div>
      </div>
      <label htmlFor="request">Any special request?</label>
      <textarea
        name="message"
        id="request"
        cols="50"
        rows="10"
        value={formik.values.message}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      ></textarea>
      {formik.values.region === "" ||
      formik.values.region === "Greater Accra Region" ? (
        <>
          <h6 style={{ marginTop: "1rem" }}>PAYMENT METHOD</h6>
          <div className="pink-line"></div>
          <div className="payment-types">
            <div>
              <input
                type="radio"
                name="payment"
                id="onSite"
                value="Payment on site"
                defaultChecked
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="onSite">Payment on site</label>
            </div>
            <div>
              <input
                type="radio"
                name="payment"
                id="onDelivery"
                value="Payment on delivery"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="onDelivery">Payment on delivery</label>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default BillingForm;
