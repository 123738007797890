import React from "react";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { db, storage } from "../../firebaseConfig";
import Loader from "../Loader";
import { v4 as uuidv4 } from "uuid";
import { close } from "../../icons";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";

function UploadImages({
  id,
  images,
  setImages,
  imageUrls,
  setImageUrls,
  isImageLoading,
  setIsImageLoading,
}) {
  const handleUpload = (e) => {
    setImages([]);
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };
  const upload = () => {
    // setImageUrls([]);
    setIsImageLoading(true);
    images.forEach((image) => {
      const storageRef = ref(storage, `images/${image.name}-${uuidv4()}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrls((prevState) => [...prevState, downloadURL]);
          });
          setIsImageLoading(false);
        }
      );
    });
  };
  const deleteImage = async (index, item) => {
    setIsImageLoading(true);
    const deleteRef = ref(storage, imageUrls[index]);
    await updateDoc(doc(db, "Products", id), {
      images: arrayRemove(item),
    });
    deleteObject(deleteRef);
    setImageUrls(imageUrls.filter((el) => el !== item));

    setIsImageLoading(false);
  };
  return (
    <>
      <h3>Images</h3>
      <input
        type="file"
        name="images"
        id="images"
        accept="image/*"
        multiple
        onChange={handleUpload}
      />

      <div className="uploaded-image">
        {imageUrls?.map((item, index) => {
          return (
            <div key={index} className="uploaded-img">
              <span className="delete" onClick={() => deleteImage(index, item)}>
                {close}
              </span>
              <img src={item} alt="" />
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        <p className="upload-btn mr-2" onClick={upload}>
          Upload
        </p>
        <p className="upload-btn" onClick={() => setImageUrls([])}>
          Clear
        </p>
      </div>
      <div className="loading">{isImageLoading ? <Loader /> : ""}</div>
    </>
  );
}

export default UploadImages;
