import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedCart({ children }) {
  const { cartItems } = useSelector((store) => store.products);

  if (cartItems.length > 0) {
    return children;
  }
  return <Navigate to="/cart" />;
}

export default ProtectedCart;
