import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebaseConfig";

// const days = [
//   "sunday",
//   "monday",
//   "tuesday",
//   "wednesday",
//   "thursday",
//   "friday",
//   "saturday",
// ];
// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
// const date = new Date();

export const fetchUser = () => {
  const userInfo =
    localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : localStorage.clear();

  return userInfo;
};
export const fetchCart = () => {
  const cartData =
    sessionStorage.getItem("cart") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("cart"))
      : sessionStorage.clear();

  return cartData;
};
export const registerUser = async (email, password, firstName, lastName) => {
  try {
    const {
      user: { providerData },
    } = await createUserWithEmailAndPassword(auth, email, password);
    // localStorage.setItem("user", JSON.stringify(providerData[0]));
    await setDoc(
      doc(db, "Users", providerData[0].email),
      {
        userId: providerData[0].uid,
        firstName: firstName,
        lastName: lastName,
        userEmail: providerData[0].email,
        role: ["user"],
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    // console.log(error.message);
    return error.message;
  }
};
export const loginUser = async (email, password) => {
  try {
    const {
      user: { providerData },
    } = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem("user", JSON.stringify(providerData[0]));
    // return providerData[0];
  } catch (error) {
    console.log(error.message);
  }
};
export const logoutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.log(error.message);
  }
};

export const resetUserPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log(error.message);
  }
};

export const saveProduct = async (data, stock, img, id) => {
  try {
    await updateDoc(
      doc(db, "Products", id),
      {
        ...data,
        ...stock,
        images: img,
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    if (error.message.includes("No document to update")) {
      await setDoc(
        doc(db, "Products", id),
        {
          ...data,
          ...stock,
          images: img,
          active: true,
          timestamp: serverTimestamp(),
        },
        {
          merge: true,
        }
      );
    }
  }
};
export const changeProductActive = async (id, value) => {
  try {
    await updateDoc(doc(db, "Products", id), {
      active: value,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (id) => {
  await deleteDoc(doc(db, "Products", id));
};

export const getUserSession = async () => {
  const items = await getDocs(query(collection(db, "UserSession")));
  return items.docs.map((doc) => doc.data());
};
export const addUserSession = async () => {
  try {
    await updateDoc(doc(db, "UserSession", new Date().toDateString()), {
      date: new Date().toDateString(),
      count: increment(1),
    });
  } catch (error) {
    if (error.message.includes("No document to update")) {
      await setDoc(doc(db, "UserSession", new Date().toDateString()), {
        date: new Date().toDateString(),
        count: increment(1),
      });
    }
  }
};

export const addOrderCount = async () => {
  try {
    await updateDoc(doc(db, "orderCount", new Date().toDateString()), {
      date: new Date().toDateString(),
      count: increment(1),
    });
  } catch (error) {
    if (error.message.includes("No document to update")) {
      await setDoc(doc(db, "orderCount", new Date().toDateString()), {
        date: new Date().toDateString(),
        count: increment(1),
      });
    }
  }
};
export const getOrderCount = async () => {
  const items = await getDocs(query(collection(db, "orderCount")));
  return items.docs.map((doc) => doc.data());
};
// export const addSessionCount = async () => {
//   try {
//     await updateDoc(doc(db, "sessionCount", new Date().toDateString()), {
//       date: new Date().toDateString(),
//       count: increment(1),
//     });
//   } catch (error) {
//     if (error.message.includes("No document to update")) {
//       await setDoc(doc(db, "sessionCount", new Date().toDateString()), {
//         date: new Date().toDateString(),
//         count: increment(1),
//       });
//     }
//   }
// };
// export const getSessionCount = async () => {
//   const items = await getDocs(query(collection(db, "sessionCount")));
//   return items.docs.map((doc) => doc.data());
// };

export const saveOrder = async (order, num) => {
  await setDoc(doc(db, "OrderHistory", num), {
    ...order,
    timestamp: serverTimestamp(),
    date: new Date().toDateString(),
  });
};

// get orders
export const getAllOrders = async () => {
  const items = await getDocs(
    query(collection(db, "OrderHistory"), orderBy("timestamp", "desc"))
  );
  return items.docs.map((doc) => doc.data());
};
// edit order state
export const changeStatus = async (num, status) => {
  await updateDoc(doc(db, "OrderHistory", num), {
    state: status,
  });
};
// newsletter

export const saveNewsLetter = async (data) => {
  await setDoc(doc(db, "NewsLetter", data.email), {
    ...data,
    timestamp: serverTimestamp(),
  });
};
//coupons
export const saveCoupon = async (data, id) => {
  await setDoc(
    doc(db, "Coupons", id),
    { ...data, timestamp: serverTimestamp() },
    {
      merge: true,
    }
  );
};

export const getCoupons = async () => {
  const items = await getDocs(query(collection(db, "Coupons")));
  return items.docs.map((doc) => doc.data());
};

export const deleteCoupon = async (id) => {
  await deleteDoc(doc(db, "Coupons", id));
};

//Category
export const saveCategory = async (data, id) => {
  await setDoc(
    doc(db, "Category", id),
    { ...data, timestamp: serverTimestamp() },
    {
      merge: true,
    }
  );
};

export const getCategories = async () => {
  const items = await getDocs(query(collection(db, "Category")));
  return items.docs.map((doc) => doc.data());
};

export const deleteCategory = async (id) => {
  await deleteDoc(doc(db, "Category", id));
};

//Collection
export const saveCollection = async (data, id) => {
  await setDoc(
    doc(db, "Collection", id),
    { ...data, timestamp: serverTimestamp() },
    {
      merge: true,
    }
  );
};

export const getCollections = async () => {
  const items = await getDocs(query(collection(db, "Collection")));
  return items.docs.map((doc) => doc.data());
};

export const deleteCollection = async (id) => {
  await deleteDoc(doc(db, "Collection", id));
};

//Reviews
export const saveReview = async (data, id) => {
  await setDoc(
    doc(db, "Reviews", id),
    { ...data, timestamp: serverTimestamp() },
    {
      merge: true,
    }
  );
};
// // save wish list
// export const saveWishList = (email, wishlist) => {
//   wishlist.forEach(async (item) => {
//     await addDoc(collection(db, "Users", email, "WishList"), {
//       ...item,
//       // id: uuidv4(),
//       date: `${date.getDate()} ${
//         months[date.getMonth()]
//       }, ${date.getFullYear()}`,
//     });
//   });
// };
// // get wishlist
// export const getWishList = async (email) => {
//   const items = await getDocs(
//     query(collection(db, "Users", email, "WishList"))
//   );
//   return items.docs.map((doc) => doc.data());
// };
