import styled from "styled-components";

export const SingleProductContainer = styled.section`
  .single-product {
    z-index: 1;
    background-color: white;
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
  .mobile-next {
    display: none;
  }
  .single-product-img {
    position: relative;
    margin-right: 2rem;
  }
  .single-product-desc {
    position: sticky;
    position: -webkit-sticky;
    top: 9rem;
  }
  .colors svg {
    margin-right: 1rem;
    font-size: 30px;
  }
  .size-text a {
    color: black;
  }
  .size-text {
    display: flex;
    width: 23rem;
    justify-content: space-between;
  }
  .size-chart-text {
    text-decoration: underline;
    text-decoration-color: #efa893;
  }

  .size-btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 23rem;
  }
  .size-btn button {
    line-height: 0.9;
    background-color: transparent;
    margin-right: 4px;
    margin-top: 4px;
    width: 5rem;
    height: 3rem;
    border: 0.12rem solid black;
  }
  #disable-size {
    pointer-events: none;
    background-color: transparent;
    color: #b7b7b7;
    border: 0.12rem solid #b7b7b7;
  }
  #disable-size::before {
    content: " ";
    display: block;
    height: 2px;
    width: 90px;
    position: relative;
    bottom: -9px;
    left: -7.5px;
    background: #b7b7b7;
    transform: rotate(-30deg);
  }
  #disable-size::after {
    content: " ";
    display: block;
    height: 2px;
    width: 90px;
    position: relative;
    top: -9px;
    left: -7.5px;
    background: #b7b7b7;
    transform: rotate(30deg);
  }

  .size-btn button:hover {
    background-color: #efa893;
    color: white;
    border: 0.12rem solid white;
  }
  .active-size {
    background-color: #efa893 !important;
    color: white;
    border: 0.12rem solid white !important;
  }
  .disabledbtn button {
    pointer-events: none;
    background-color: transparent;
    color: #b7b7b7;
    border: 2px solid black;
    width: 23rem;
    height: 4rem;
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
  }
  .addtocartbtn button {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid #efa893;
    width: 23rem;
    height: 4rem;
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
  }

  .addtocartbtn button:hover {
    background-color: #efa893;
    color: white;
    border: none;
  }

  .size-list {
    padding-left: 16px;
  }
  .size-list li {
    color: #efa893;
  }
  .size-list span {
    color: black;
  }

  .aboutProduct p,
  .aboutProduct li {
    font-size: 1rem;
    font-weight: 300;
  }
  .name {
    font-size: 2rem;
  }
  .product-price {
    font-size: 1.3rem;
    margin: 0;
    margin-top: 10px;
  }
  .product-price span {
    display: block;
    opacity: 0.7;
    text-decoration: line-through;
  }
  .hide-out {
    display: none;
  }
  .out {
    width: 23rem;
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
  }
  .item-quantity-table {
    height: 3rem;
    border: 1px solid black;
    text-align: center;
    display: inline-flex;
    align-items: center;
    font-size: 1.5rem;
  }
  .item-quantity-table p {
    margin: 0;
  }
  .item-quantity-table button {
    border: none;
    background-color: transparent;
    color: black;
  }
  .item-quantity-table p:nth-child(1),
  .item-quantity-table p:nth-child(3) {
    width: 2rem;
    cursor: pointer;
  }

  .write-review {
    background-color: white;
    display: flex;
    justify-content: flex-end;
  }
  .write-review button {
    margin: 1rem 0;
    border: 2px solid #efa893;
    background-color: transparent;
    color: black;
    margin-right: 0.5rem;
    width: 10rem;
    height: 3rem;
  }
  .write-review button:hover {
    background-color: #efa893;
    color: white;
  }

  @media (max-width: 800px) {
    .single-product-img {
      margin-right: 0;
    }
    .size-text {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .size-btn button {
      line-height: 0.9;
      background-color: transparent;
      color: black;
      margin-right: 0.5rem;
      width: 5rem;
      height: 3rem;
      border: 0.12rem solid black;
    }
    .addtocartbtn button {
      background-color: transparent;
      border: 2px solid #efa893;
      color: black;
      width: 15rem;
      height: 4rem;
      text-align: center;
      padding: 1rem;
    }
    .disabledbtn button {
      width: 15rem;
    }
    .size-btn {
      width: 18rem;
    }
    .out {
      width: 15rem;
    }
  }
  @media (max-width: 767px) {
    .mobile-next {
      margin: 8px 0;
      display: flex;
      align-items: center;
      // justify-content: flex-end;
    }
    .next {
      display: none;
    }
  }
`;
