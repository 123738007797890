import styled from "styled-components";

export const AddProductSection = styled.section`
   {
    pointer-events: all;
    position: fixed;
    top: 1rem;
    bottom: 1rem;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem auto;
    background-color: white;
    width: 70vw;
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 1rem;
  }
  .close-modal svg {
    height: 2rem;
    color: red;
    border: 1px solid red;
    border-radius: 4px;
  }
  .msg {
    text-align: center;
    height: 3rem;
  }
  .form {
    display: flex;
    justify-content: space-between;
  }
  .right,
  .left {
    width: 40%;
  }
  label {
    display: block;
  }
  .checkboxes {
    display: flex;
    justify-content: space-between;
    width: 96%;
  }
  .checkboxes select {
    width: 7rem;
    font-size: small;
    margin-bottom: 8px;
  }
  .discount {
    position: relative;
    display: flex;
  }
  .discount span {
    color: #84827e;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 6px;
    pointer-events: none;
  }
  #discount,
  #price {
    border: 1px solid black;
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
    padding-left: 2.3rem;
    margin-bottom: 0.5rem;
  }
  #name,
  #category,
  #collection,
  #tags {
    border: 1px solid black;
    width: 96%;
    height: 2rem;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
  #description,
  #features {
    border: 1px solid black;
    width: 96%;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sizes {
    display: flex;
    justify-content: space-between;
  }
  .stock-desc-quan input {
    width: 48%;
    border: 1px solid black;
    height: 2rem;
    padding: 0 0.5rem;
    margin: 0.5rem 0;
  }
  .stock {
    display: flex;
    justify-content: space-between;
  }
  .stock-icons {
    display: flex;
  }
  .stock-icons svg {
    height: 2rem;
    border-radius: 6px;
    padding: 2px;
  }
  .deleteIcon svg {
    color: #f86d7c;
    border: 1px solid #f86d7c;
    margin-right: 0.5rem;
  }
  .addIcon svg {
    color: #43cfc9;
    border: 1px solid #43cfc9;
  }
  .form-btns {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
  }
  .form-btns button {
    margin-left: 1rem;
  }
  .uploaded-image {
    position: relative;
    min-height: 10rem;
    width: 35rem;
    display: flex;
    flex-wrap: wrap;
  }
  .upload-btn {
    cursor: pointer;
    margin: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: transparent;
    border-radius: 12px;
    height: 2.5rem;
    font-weight: 600;
    width: 8rem;
  }
  .uploaded-img {
    position: relative;
    height: 5rem;
    width: 5rem;
    margin: 0.5rem;
  }
  .uploaded-img img {
    border: 1px solid black;
    height: inherit;
    width: inherit;
  }

  .delete svg {
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.473);
    height: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .close-btn {
    color: #f86d7c;
    background-color: transparent;
    border: 1px solid #f86d7c;
    border-radius: 6px;
    padding: 0.5rem;
    margin-right: 1rem;
  }
  .add-btn {
    color: #43cfc9;
    background-color: transparent;
    border: 1px solid #43cfc9;
    border-radius: 6px;
    padding: 0.5rem;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
