export const productFields = {
  name: "",
  category: "",
  collection: "",
  description: "",
  features: "",
  newArrivals: false,
  mainSale: false,
  mainCollection: false,
  tags: "",
  images: [],
  price: "",
  discount: "",
};

export const stockFields = {
  XS: 0,
  Small: 0,
  Medium: 0,
  Large: 0,
  XL: 0,
  XXL: 0,
};
