import React, { useEffect } from "react";
import { ReturnPolicySection } from "./styles/ReturnPolicy.styled";

function MakeARetrurn() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Return Process";
  }, []);
  return (
    <ReturnPolicySection>
      <div className="container ">
        <div className="row">
          <div className="col-md-12 mt-3 text-center">
            <h1 className="">Return Process</h1>
          </div>
        </div>
      </div>
      <div className="container return-policy">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li>
                <h3>Email us</h3>
                <p>
                  Send us an email via{" "}
                  <a href="mailto:customerservice@lomokie.com">
                    customerservice@lomokie.com
                  </a>{" "}
                  and describe the items you would like to return and the reason
                  for your return. Attach photographs
                </p>
              </li>
              <li>
                <h3>Take suggestions</h3>
                <p>Professional suggestions will be offered by our team</p>
              </li>
              <li>
                <h3>Return the goods</h3>
                <p>
                  Put the items in its original packaging and send it through
                  local shipping guy or otherwise stated in our response email
                  with shipping details. You will need to pay for the cost of
                  shipping the goods to us. We have the right to refuse the
                  return if shipping cost paid is insufficient. LMK will only be
                  responsible for shipping cost of defective products.
                </p>
              </li>
              <li>
                <h3>Exchange</h3>
                <p>
                  Once we receive your package, you will be informed and your
                  exchange will be processed within 3 working days.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ReturnPolicySection>
  );
}

export default MakeARetrurn;
