import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { bars, cart, close } from "../icons";
import Swipers from "./Swiper";
import { MyNav } from "./styles/Navbar.styled";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../features/user/userSlice";
import {
  calculateTotals,
  closeOverlay,
} from "../features/product/productSlice";
import CartOverlay from "./cart/CartOverlay";
import { SearchComp, SearchCompMobile } from "./SearchComp";
import { setFilter } from "../features/product/productSlice";

function NavbarComp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems, amount, isOverlayOpen } = useSelector(
    (store) => store.products
  );
  const { user } = useSelector((store) => store.user);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState("");

  let overlayRef = useRef();
  let sidebarRef = useRef();

  useEffect(() => {
    dispatch(calculateTotals());
    sessionStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems, dispatch]);

  useEffect(() => {
    const closeSidebar = (e) => {
      if (!sidebarRef.current.contains(e.target)) {
        setSidebarOpen(false);
      }
    };
    document.addEventListener("mouseup", closeSidebar);

    return () => {
      document.removeEventListener("mouseup", closeSidebar);
    };
  });
  useEffect(() => {
    const handleClose = (e) => {
      if (!overlayRef.current.contains(e.target)) {
        dispatch(closeOverlay());
      }
    };
    document.addEventListener("mouseup", handleClose);

    return () => {
      document.removeEventListener("mouseup", handleClose);
    };
  });

  const dispatchFilter = (value) => {
    dispatch(setFilter(value));
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <MyNav className="fixed-top">
      <nav id="navbar">
        <aside
          ref={overlayRef}
          className={isOverlayOpen ? "cart-overlay" : "hide-overlay"}
        >
          <CartOverlay />
        </aside>
        <div className="first-nav">
          <div className="logo-container">
            <Link to="/">
              <img src="/images/logo.png" alt="logo" className="logo" />
            </Link>
          </div>
          <div className="nav-search">
            <SearchComp search={search} setSearch={setSearch} />
          </div>
          <ul className="first-nav-links">
            <Link to="/size-chart">
              <li>Size chart</li>
            </Link>
            {user ? (
              <li onClick={() => dispatch(clearUser())}>Sign out</li>
            ) : (
              <Link to="/login">
                <li>Sign in</li>
              </Link>
            )}
            {user && user?.role?.includes("admin") && (
              <Link to="/admin">
                <li>Admin</li>
              </Link>
            )}
            <Link to="/order-history">
              <li>Orders</li>
            </Link>
            <li>
              <Link to="/cart">
                <div className="cart">
                  <div className="main-cart">
                    <span>Cart</span>
                    <span className="cart-numb">{amount}</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="first-announcement">
          <p>SHOP WEEKLY NEW ARRIVALS</p>
        </div>
        <div className="second-announcement">
          <p>FREE DELIVERY FOR ITEMS GHC180 OR MORE WITHIN GHANA</p>
        </div> */}
        <div className="nav-announcement text-center ">
          <Swipers />
        </div>
        <div className="second-nav">
          <ul className="second-nav-links">
            <li onClick={() => navigate("/new-arrivals")}>NEW ARRIVALS</li>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">SORT BY</Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => dispatch(setFilter("low to high"))}
                >
                  Sort by price: low to high
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => dispatch(setFilter("high to low"))}
                >
                  Sort by price: high to low
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => dispatch(setFilter("popularity"))}
                >
                  Sort by popularity
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => dispatch(setFilter("latest"))}>
                  Sort by latest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link to="/products/dresses">
              <li>DRESSES</li>
            </Link>
            <Link to="/products/sets">
              <li>SETS</li>
            </Link>
            <Link to="/products/tops">
              <li>TOPS</li>
            </Link>
            <Link to="/products/bottoms">
              <li>BOTTOMS</li>
            </Link>
            <Link to="/sale">
              <li>SALE</li>
            </Link>
            <Link to="/size-chart">
              <li>Size chart</li>
            </Link>
            <Link to="/reviews">
              <li>REVIEWS</li>
            </Link>
          </ul>
        </div>
      </nav>
      <nav id="mobile-nav">
        <aside className={isOverlayOpen ? "cart-overlay" : "hide-overlay"}>
          <CartOverlay />
        </aside>
        <div className="mobile-logo">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="logo"
              className="nav-mobile-logo mx-auto"
            />
          </Link>
        </div>
        <div className="main-mobile-nav">
          <div
            className="hamgurger-icon"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {bars}
          </div>

          <div className="mobile-nav-search">
            <SearchCompMobile search={search} setSearch={setSearch} />
          </div>
          <div className="cart">
            <div className="cart-icon">
              <Link to="/cart">
                {cart}
                <span className="cart-number">{amount}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="mobile-nav-announcement text-center ">
          <Swipers />
        </div>
        <aside
          ref={sidebarRef}
          className={sidebarOpen ? "nav-sidebar" : "hide-sidebar"}
        >
          <span
            className="close-side"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {close}
          </span>
          <ul
            className="mobile-nav-links"
            // onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Link to="/new-arrivals">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                NEW ARRIVALS
              </li>
            </Link>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">SORT BY</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => dispatchFilter("low to high")}>
                  Sort by price: low to high
                </Dropdown.Item>
                <Dropdown.Item onClick={() => dispatchFilter("high to low")}>
                  Sort by price: high to low
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={() => dispatchFilter("popularity")}
                >
                  Sort by popularity
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => dispatchFilter("latest")}>
                  Sort by latest
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link to="/products/dresses">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                DRESSES
              </li>
            </Link>
            <Link to="/products/sets">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                SETS
              </li>
            </Link>
            <Link to="/products/tops">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                TOPS
              </li>
            </Link>
            <Link to="/products/bottoms">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                Bottoms
              </li>
            </Link>
            <Link to="/sale">
              <li
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                SALE
              </li>
            </Link>
            <Link to="/size-chart">
              <li onClick={() => setSidebarOpen(!sidebarOpen)}>Size chart</li>
            </Link>
            <Link to="/reviews">
              <li onClick={() => setSidebarOpen(!sidebarOpen)}>REVIEWS</li>
            </Link>

            {user &&
              user?.role?.includes("admin") &&
              process.env.REACT_APP_ADMIN_EMAIL.includes(user?.userEmail) && (
                <Link to="/admin">
                  <li>Admin</li>
                </Link>
              )}
            <Link to="/order-history">
              <li onClick={() => setSidebarOpen(!sidebarOpen)}>Orders</li>
            </Link>
            {user ? (
              <li
                onClick={() => {
                  dispatch(clearUser());
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                Sign out
              </li>
            ) : (
              <Link to="/login">
                <li
                  onClick={() => {
                    setSidebarOpen(!sidebarOpen);
                  }}
                >
                  Sign in
                </li>
              </Link>
            )}
          </ul>
        </aside>
      </nav>
    </MyNav>
  );
}

export default NavbarComp;
