import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductContainerSection } from "./styles/ProductsContainer.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper";
import PinkBar from "./PinkBar";

function ProductsContainer() {
  const { products } = useSelector((store) => store.products);
  return (
    <>
      <div className="text-center mt-3">
        <h3>Stand out in LMK pieces</h3>
      </div>

      <PinkBar />
      <ProductContainerSection className="product-container">
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {products?.map((item) => {
              const { name, id, images, price, discount } = item;
              return (
                <SwiperSlide key={id}>
                  <Link to={`/product/${id}`}>
                    <article className="product-items">
                      <div className="img-container">
                        <LazyLoadImage
                          src={images[0]}
                          placeholderSrc="/images/1-min.jpg"
                          effect="blur"
                        />
                      </div>
                      <div className="caption">
                        <div className="name">
                          <p>{name}</p>
                        </div>
                        {discount > 0 ? (
                          <p className="product-price price">
                            <span>Ghc {price}</span> GHc {discount}
                          </p>
                        ) : (
                          <p className="product-price price">Ghc {price}</p>
                        )}
                      </div>
                    </article>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      </ProductContainerSection>
    </>
  );
}

export default ProductsContainer;
