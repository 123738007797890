import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisterSection } from "./styles/Register.styled";
import * as yup from "yup";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../features/user/userSlice";
import { auth, db } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query } from "firebase/firestore";

function SignIn() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const [msg, setMsg] = useState("");
  const [userData, setUserData] = useState(null);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please Enter Valid Email")
      .required("Email Required"),
    password: yup.string().min(8).required("Password Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      handleOpen();
      try {
        await signInWithEmailAndPassword(
          auth,
          formik.values.email,
          formik.values.password
        );
        const items = await getDocs(query(collection(db, "Users")));
        const tempItem = items.docs.map((doc) => doc.data());
        setUserData(
          tempItem.find((item) => item.userEmail === formik.values.email)
        );
        localStorage.setItem(
          "user",
          JSON.stringify(
            tempItem.find((item) => item.userEmail === formik.values.email)
          )
        );
      } catch (error) {
        setMsg(error.message);
      }
      handleClose();
    },
    validationSchema,
  });
  useEffect(() => {
    dispatch(setUser(userData));
    if (user) {
      navigate("/");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Login";
  }, []);

  return (
    <RegisterSection>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="register-form" onSubmit={formik.handleSubmit}>
        <h1>Login</h1>
        <h5>
          {msg?.includes("wrong-password") || msg?.includes("user-not-found")
            ? "Wrong Email/Password"
            : ""}
        </h5>
        <div className="email">
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.email && formik.errors.email ? (
            <p>{formik.errors.email}</p>
          ) : null}
        </div>
        <div className="password">
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.password && formik.errors.password ? (
            <p>{formik.errors.password}</p>
          ) : null}
        </div>
        <button type="submit" className="submit-btn">
          Login
        </button>

        <h6>
          Don't have an account yet?{" "}
          <Link to="/create-account"> Sign Up here</Link>{" "}
        </h6>
      </form>
    </RegisterSection>
  );
}

export default SignIn;
