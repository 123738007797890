import styled from "styled-components";

export const OrdersSection = styled.div`
  table {
    margin: 2rem;
    box-shadow: 1px 1px 20px 1px rgb(151, 151, 151);
  }
  th {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 2rem 1rem;
    border-bottom: 1px solid #efa893;
  }
  td {
    font-size: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #efa893;
  }
  .order-no {
    width: 10rem;
  }
  .order-no-body {
    padding-left: 1.5rem;
    width: 10rem;
  }
  .name {
    width: 12rem;
  }
  .date {
    width: 10rem;
  }
  .amount {
    width: 10rem;
  }
  .state {
    width: 8rem;
  }
  .action {
    text-align: center;
    width: 7rem;
  }
  span svg {
    cursor: pointer;
    height: 2rem;
  }
  #dropdown-basic:focus {
    box-shadow: none;
  }
  #dropdown-basic {
    padding: 0;
    font-size: 1rem !important;
    outline: none !important;
    border: none !important;
    border-radius: 0;
    background-color: transparent;
    color: inherit;
    font: inherit;
    font-weight: inherit !important;
    text-transform: none;
  }
  .dropdown-menu {
    margin-top: 0.5rem !important;
    // border: 0 !important;
    border-radius: 0px !important;
    text-transform: capitalize;
  }
  .dropdown-item:hover {
    color: #efa893 !important;
  }
`;
