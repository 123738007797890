import { createSlice } from "@reduxjs/toolkit";
import { fetchUser } from "../../utils/firebaseFunctions";

const userInfo = fetchUser();

const initialState = {
  user: userInfo,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.clear();
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
