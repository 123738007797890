import styled from "styled-components";

export const CartSection = styled.section`
   {
    min-height: 50vh;
    margin-top: 2rem;
  }
`;

export const ShoppingCartSection = styled.section`
  .empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .empty-cart button {
    border: 0;
    background-color: #f5cbae;
    height: 4rem;
    width: 15rem;
    margin: 2rem 0;
    font-size: 1.2rem;
  }
  .empty-cart button a {
    color: black;
    text-decoration: none;
  }
  .shopping-cart {
    margin: 2rem 0;
  }
  .shopping-cart-mobile {
    display: none;
    margin: 2rem 0;
  }

  .stages {
    display: flex;
    align-items: center;
    margin-bottom: 7rem;
  }
  .stages h3 {
    margin-bottom: 0;
    color: grey;
  }
  .stages span {
    margin-right: 1rem;
    font-size: 2rem;
    width: 3.3rem;
    text-align: center;
    border-radius: 50%;
    color: grey;
    border: 2px solid #efa893;
  }
  table {
    width: 100%;
  }
  thead,
  tfoot {
    border-bottom: 4px solid #efa893;
    margin: 1rem 0;
    color: grey;
  }
  .table-product {
    width: 35%;
  }
  .table-product h5 {
    margin-bottom: 0;
  }
  .table-product p {
    margin-bottom: 0;
    color: gray;
  }
  th {
    width: 10%;
  }
  td {
    padding-top: 2rem;
  }
  .item-quantity-table {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-quantity-table p {
    text-align: center;
    border: 1px solid #efa893;
    margin-bottom: 0;
    /* font-size: 2rem; */
    width: 2rem;
    height: 2rem;
    margin-bottom: 0;
    padding: 0.2rem;
  }
  .your-order-item {
    display: flex;
  }
  .your-order-item img {
    height: 7rem;
    margin-right: 0.3rem;
  }
  button {
    color: black;
  }
  .remove-btn {
    margin-top: 1rem;
    border: 0;
    border-bottom: 1px solid #efa893;
    background-color: transparent;
  }
  .promo-code {
    display: flex;
  }
  .promo-code input {
    height: 2rem;
    padding: 0.4rem;
    border: 1px solid #efa893;
    border-radius: 0;
  }
  .promo-code button {
    height: 2rem;
    width: 2rem;
    border: 1px solid #efa893;
    background-color: #efa893;
    color: white;
  }
  .return-to-shop-btn {
    background-color: transparent;
    border: 0;
  }
  .return-to-shop-btn::before {
    content: "<";
    color: #efa893;
  }
  .update-cart-btn {
    background-color: transparent;
    border: 1px solid #efa893;
    padding: 0.5rem;
    min-width: 10rem;
    margin-left: 1rem;
  }
  .item-quantity-table p:nth-child(1),
  .item-quantity-table p:nth-child(3) {
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    .stages {
      display: none;
    }
    .shopping-cart-mobile {
      display: block;
    }
    .shopping-cart {
      display: none;
    }
    .table-product {
      width: 90%;
      text-align: left;
    }
    .mobile-total {
      margin-top: 2rem;
    }
    .mobile-total h5 {
      text-align: right;
      color: gray;
      font-size: 1rem;
    }
    th {
      width: 10%;
      text-align: right;
    }
    .item-quantity-table {
      float: right;
      width: 65%;
    }
    .promo-code {
      justify-content: center;
      margin: 1rem auto;
    }
  }
  @media (max-width: 670px) {
    .table-product h5 {
      font-size: 0.9rem;
    }
    .table-product p {
      font-size: 0.7rem;
    }
  }
`;

export const CheckoutSection = styled.section`
  .err-msg {
    height: 2rem;
    color: rgb(255, 45, 45);
  }
  .err-msg p {
    margin: 0;
  }
  .checkout {
    display: flex;
  }
  .stages {
    display: flex;
    align-items: center;
    margin-bottom: 7rem;
  }
  .stages h3 {
    margin-bottom: 0;
    color: grey;
  }
  .stages span {
    margin-right: 1rem;
    font-size: 2rem;
    width: 3.3rem;
    text-align: center;
    border-radius: 50%;
    color: grey;
    border: 2px solid #efa893;
  }
  .pink-line {
    background-color: #efa893;
    height: 4px;
    margin: 1rem 0;
  }
  .billing-details {
    margin-bottom: 2rem;
    width: 55%;
  }

  /* form */
  label {
    color: grey;
    font-size: 1.3rem;
    // margin-top: 1rem;
  }
  input {
    outline: 0;
    height: 2rem;
    padding: 4px;
    border-radius: 0;
    border: 1px solid black;
  }
  .required::before {
    content: "*";
    color: #efa893;
  }
  #region {
    width: 98%;
    margin-right: 1rem;
    height: 2rem;
    border-radius: 0;
    border: 1px solid black;
    color: black;
    background-color: transparent;
  }
  .region-city {
    width: 98%;
    display: flex;
    justify-content: space-between;
  }
  .city,
  .region {
    width: 100%;
  }
  #name {
    width: 98%;
  }
  #city {
    width: 100%;
  }
  .phone-email {
    width: 98%;
    display: flex;
    justify-content: space-between;
  }
  .phone {
    width: 98%;
  }
  .email {
    width: 100%;
  }
  #phone {
    width: 98%;
  }
  #email {
    width: 100%;
  }
  #request {
    outline: 0;
    width: 98%;
    height: 8rem;
  }
  .payment-types div {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }
  .payment-types label {
    margin: 0;
  }
  #onSite,
  #onDelivery {
    margin-right: 0.5rem;
  }
  .delivery-note {
    margin-top: 1rem;
    background-color: #efa893;
    padding: 0.5rem 1rem;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* your order  */
  #your-order {
    position: relative;
    margin: 0 2rem;
    width: 45%;
  }
  .your-order {
    padding: 1.5rem;
    border: 4px solid #efa893;
    width: 100%;
    min-height: 30rem;
  }
  .order-pink-line {
    height: 2px;
    background-color: #efa893;
  }
  .your-orders {
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .your-orders p {
    margin-bottom: 0;
    color: grey;
  }
  .your-orders h6 {
    font-size: 1.2rem;
    color: grey;
  }
  .your-order-item {
    display: flex;
  }
  .your-order-item img {
    height: 7rem;
    margin-right: 0.3rem;
  }
  .order-price span {
    font-size: 0.7rem;
  }
  .totals div {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }
  .order-privacy-note {
    color: rgba(0, 0, 0, 0.753);
    font-size: 1.2rem;
  }
  .order-privacy-note a {
    color: rgba(0, 0, 0, 0.753);
  }
  .place-order {
    display: flex;
    justify-content: center;
  }
  .place-order-btn {
    background-color: #efa893;
    color: black;
    border: 0;
    height: 2rem;
    width: 90%;
    margin: 1rem auto;
  }

  @media (max-width: 1200px) {
    #your-order h5 {
      font-size: 0.8rem;
    }
    #your-order h6 {
      font-size: 0.8rem;
    }
    #your-order p {
      font-size: 0.7rem;
    }
    .stages {
      display: none;
    }
    /* form  */
    #name {
      width: 100%;
    }
    #region {
      padding: 0.2rem;
      width: 100%;
      margin-right: 1rem;
      height: 2rem;
      border-radius: 0;
      border: 1px solid black;
      color: black;
      background-color: transparent;
    }
    .region-city {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .city,
    .region {
      width: 100%;
    }
    #city {
      width: 100%;
    }
    .phone-email {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .phone {
      width: 100%;
    }
    .email {
      width: 100%;
    }
    #phone {
      width: 100%;
    }
    #email {
      width: 100%;
    }
    #request {
      outline: 0;
      width: 100%;
      height: 8rem;
    }
    /* form  */
    .checkout {
      flex-direction: column;
    }
    .billing-details {
      width: 100%;
    }
    #your-order {
      border: 4px solid #efa893;
      margin: 0;
      padding: 1.5rem;
      width: 100%;
    }
    .your-order {
      padding: 0;
      border: 0;
    }
  }
  @media (max-width: 1000px) {
    .stages {
      display: none;
    }
  }
`;

export const OrderStatusSection = styled.section`
  .order-status {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .stages {
    display: flex;
    align-items: center;
    margin-bottom: 7rem;
  }
  .stages h3 {
    margin-bottom: 0;
    color: grey;
  }
  .stages span {
    margin-right: 1rem;
    font-size: 2rem;
    width: 3.3rem;
    text-align: center;
    border-radius: 50%;
    color: grey;
    border: 2px solid #efa893;
  }
  .order-status li {
    /* list-style: none; */
    color: #efa893;
    font-size: 2rem;
  }
  .order-status li span {
    color: black;
  }
  @media (max-width: 1000px) {
    .stages {
      display: none;
    }
  }
  @media (max-width: 700px) {
    .order-status {
      min-height: 23rem;
      align-items: center;
    }
    .order-status ul {
      margin-top: 3rem;
    }
    .order-status li {
      font-size: 1rem;
      margin: 4px 0;
    }
  }
`;

export const CartOverlaySection = styled.section`
  & a {
    color: black;
  }
   {
    box-shadow: 1px 1px 10px 1px rgb(206, 206, 206);
    width: 35rem;
    min-height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding: 1.5rem;
  }
  .top {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    justify-content: space-between;
  }
  .close-btn svg {
    cursor: pointer;
    height: 2rem;
  }
  .cartIcon {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cartIcon a {
    color: black;
  }
  .cart-num {
    position: absolute;
    top: 1.5rem;
    left: 3.8rem;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: #efa893;
    text-align: center;
    margin-bottom: 0;
  }
  .cartIcon svg {
    height: 3rem;
    margin-left: 0.5rem;
  }

  .cartoverlay-item {
    width: inhehit;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .cartoverlay-item img {
    height: 8rem;
    margin-right: 1rem;
  }
  .cartoverlay-item-info {
    width: 75%;
  }
  .remove-icon svg {
    cursor: pointer;
    padding: 2px;
    height: 1.2rem;
    border-radius: 50%;
    background-color: #efa893;
    color: white;
  }
  .cartoverlay-item-info p {
    font-size: 14px;
  }
  .cartoverlay-item-info h6,
  .cartoverlay-item-info h5,
  .cartoverlay-item-info p {
    margin-bottom: 0;
  }
  .cart-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  .checkout:hover,
  .continue:hover {
    background-color: #efa893;
    color: white;
  }
  .checkout,
  .continue {
    color: black;
    border: 1px solid #efa893;
    height: 4rem;
    width: 100%;
    background-color: transparent;
    margin-top: 8px;
  }
  @media (max-width: 670px) {
     {
      width: 100vw;
      background-color: white;
      padding: 1.5rem;
      // overflow: scroll;
    }
  }
`;
