import React, { useState, useEffect } from "react";
import PinkBar from "./PinkBar";
import { SampleReviewSection } from "./styles/SampleReview.styled";
import { RiDoubleQuotesR } from "react-icons/ri";
import { GoStar } from "react-icons/go";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SampleReviews() {
  const { reviews } = useSelector((store) => store.reviews);
  const [avstars, setAvstars] = useState(0);

  useEffect(() => {
    reviews.forEach((item) => {
      const average = (item.stars * reviews.length) / reviews.length;
      setAvstars(average);
    });
  }, [reviews]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Reviews";
  }, []);

  return (
    <>
      <SampleReviewSection>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <h5>Reviews; share your experience with us</h5> */}
              <h2 className="text-center">Customer Reviews</h2>
            </div>
            {/* <div className="col-md-6">
            <button className="leave-review w-25">Leave a review</button>
          </div> */}
          </div>
        </div>
        <PinkBar />
        <div className="container">
          <div className="row mt-5 sample-reviews">
            <hr />
            <div className="stars-top">
              <div>
                {Array(parseInt(avstars))
                  .fill()
                  .map((star, index) => {
                    return (
                      <span key={index}>
                        <GoStar />
                      </span>
                    );
                  })}
              </div>
              <p className="sample-reviews-date">{reviews?.length} Reviews</p>
            </div>
            <hr />
            {reviews?.map((item) => {
              const {
                name,
                id,
                review,
                productName,
                productId,
                title,
                stars,
                timestamp,
              } = item;
              return (
                <div className="col-md-12 my-2" key={id}>
                  <div className="media">
                    <RiDoubleQuotesR className="sample-reviews-icon" />
                    <div className="media-body">
                      <div className="stars">
                        <div>
                          {Array(parseInt(stars))
                            .fill()
                            .map((star, index) => {
                              return (
                                <span key={index}>
                                  <GoStar />
                                </span>
                              );
                            })}
                        </div>
                        <p className="sample-reviews-date">
                          {timestamp.toDate().toDateString()}
                        </p>
                      </div>
                      <h5>{title}</h5>
                      <p>{review}</p>
                      <h6 className="sample-reviews-name">{name}</h6>

                      <br />
                      <Link to={`/product/${productId}`}>
                        <p>On {productName}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </SampleReviewSection>
    </>
  );
}

export default SampleReviews;
