import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MyFooter } from "./styles/Footer.styled";
import { saveNewsLetter } from "../utils/firebaseFunctions";

function Footer() {
  const [newsletter, setNewsletter] = useState({ phone: "", email: "" });
  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewsletter({ ...newsletter, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newsletter.phone || !newsletter.email) {
      setMsg("All fields required");
      setTimeout(() => {
        setMsg("");
      }, 2000);
    } else {
      saveNewsLetter(newsletter);
      setMsg("Thank you for joining the family");
      setNewsletter({ phone: "", email: "" });
      setTimeout(() => {
        setMsg("");
      }, 3000);
    }
  };
  return (
    <>
      <MyFooter>
        <footer>
          <div className="footer">
            <Link to="/">
              <img
                src="/images/logo01.png"
                alt="Logo"
                className="footer-logo img-fluid"
              />
            </Link>
            <ul className="footer-links">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/size-chart">Size Chart</Link>
              </li>
              <li>
                <Link to="/return-policy">Return Policy</Link>
              </li>
              <li>
                <Link to="/return-process">Make A Return</Link>
              </li>
              <li>Contact Us</li>
              <li>Shipping Policy</li>
              <li>FAQs</li>
            </ul>
            <div className="newsletter">
              <h3>JOIN LMK</h3>
              <p>
                Enjoy discounts and be among the first to know when we have new
                arrivals.
              </p>
              <form>
                <div className="newsletter-msg">
                  <h6>{msg}</h6>
                </div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={newsletter.phone}
                  onChange={handleChange}
                />
                <br />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={newsletter.email}
                  onChange={handleChange}
                />
                <br />
                <button type="submit" onClick={handleSubmit}>
                  Sign Up
                </button>
              </form>
            </div>
          </div>
          <div className="footer-mobile">
            <Link to="/">
              <img
                src="/images/logo01.png"
                alt="Logo"
                className="footer-logo"
              />
            </Link>
            <div className="newsletter">
              <h2>JOIN LMK</h2>
              <p>
                Enjoy discounts and be among the first to know when we have new
                arrivals.
              </p>
              <form>
                <div className="newsletter-msg">
                  <h6>{msg}</h6>
                </div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={newsletter.phone}
                  onChange={handleChange}
                />
                <br />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={newsletter.email}
                  onChange={handleChange}
                />
                <br />
                <button type="submit" onClick={handleSubmit}>
                  Sign Up
                </button>
              </form>
            </div>
            <ul className="footer-links">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/size-chart">Size Chart</Link>
              </li>
              <li>
                <Link to="/return-policy">Return Policy</Link>
              </li>
              <li>
                <Link to="/return-process">Make A Return</Link>
              </li>
              <li>Contact Us</li>
              <li>Shipping Policy</li>
              <li>FAQs</li>
            </ul>
          </div>
        </footer>
        <div className="copyright">
          <p>&copy;{new Date().getFullYear()} Lomokie</p>
        </div>
      </MyFooter>
    </>
  );
}

export default Footer;
