import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisterSection } from "./styles/Register.styled";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { validationSchema } from "../validations/UserValidation";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";

function SignUp() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      handleOpen();
      try {
        const {
          user: { providerData, uid },
        } = await createUserWithEmailAndPassword(
          auth,
          formik.values.email,
          formik.values.password
        );
        await setDoc(
          doc(db, "Users", providerData[0].email),
          {
            // userId: providerData[0].uid,
            userId: uid,
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            userEmail: providerData[0].email,
          },
          {
            merge: true,
          }
        );
        navigate("/login");
      } catch (error) {
        setMsg(error.message);
      }
      handleClose();
    },
    validationSchema,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Create Account";
  }, []);

  return (
    <RegisterSection>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="register-form" onSubmit={formik.handleSubmit}>
        <h1>Create Account</h1>
        <h5>{msg?.includes("already") ? "User Already exists" : ""}</h5>
        <div className="firstname">
          <input
            type="firstName"
            name="firstName"
            placeholder="First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.firstName && formik.errors.firstName ? (
            <p>{formik.errors.firstName}</p>
          ) : null}
        </div>
        <div className="lastname">
          <input
            type="lastName"
            name="lastName"
            placeholder="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.lastName && formik.errors.lastName ? (
            <p>{formik.errors.lastName}</p>
          ) : null}
        </div>
        <div className="email">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.email && formik.errors.email ? (
            <p>{formik.errors.email}</p>
          ) : null}
        </div>
        <div className="password">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="err-msg">
          {formik.touched.password && formik.errors.password ? (
            <p>{formik.errors.password}</p>
          ) : null}
        </div>
        <button type="submit" className="submit-btn">
          Register
        </button>

        <h6>
          Already have an account? <Link to="/login">Login</Link>
        </h6>
      </form>
      {/* at least 8 characters including a number and a lowercase letter. */}
    </RegisterSection>
  );
}

export default SignUp;
