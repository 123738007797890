import styled from "styled-components";

export const SingleProductImg = styled.div`
  #main-single-product-image img {
    margin-bottom: 0.5rem;
  }
  #main-single-product-image-mobile {
    display: none;
  }
  // .img-container{

  // }

  @media (max-width: 767px) {
    #main-single-product-image {
      display: none;
    }
    #main-single-product-image-mobile {
      display: flex;
      flex-direction: column;
    }
    img-container {
      height: 110px;
      width: 76px;
    }
    .mobile-slider {
      margin-top: 4px;
    }
    #main-single-product-image-mobile div img {
      width: 7rem;
    }
  }
  @media (max-width: 600px) {
    #main-single-product-image-mobile div img {
      width: 5rem;
    }
  }
`;
