import styled from "styled-components";

export const SampleReviewSection = styled.section`
  & a {
    color: grey;
    text-decoration: none;
  }
  hr {
    width: 95%;
    margin: 1rem auto;
  }
  .sample-reviews {
    color: grey;
    margin-bottom: 5rem;
  }
  .sample-reviews p {
    margin-bottom: 0;
  }
  .sample-reviews h5 {
    margin-bottom: 0;
  }
  .sample-reviews-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid rgba(128, 128, 128, 0.132);
    margin-right: 0.5rem;
    background-color: rgba(128, 128, 128, 0.231);
  }
  .stars-top {
    display: flex;
    align-items: center;
  }
  .stars-top p {
    color: black;
    font-size: 2.5rem;
  }
  .stars {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .stars svg {
    color: #efa893;
    font-size: larger;
  }
  .sample-reviews-date {
    font-size: 0.7rem;
    font-style: italic;
  }
  .sample-reviews-name {
    font-size: 0.8rem;
  }
  .leave-review {
    background-color: #efa893;
    border: 0;
    float: right;
  }
`;
