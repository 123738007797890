import React, { useState, useEffect, useCallback, useRef } from "react";
import { MoreIcon } from "../../icons";
import { OrderSection } from "../styles/Orders.styles";
import { OrdersSection } from "../styles/SingleOrders.styled";
import AdminDates from "./AdminDates";
import OrdersList from "./OrdersList";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrders,
  fetchSingleOrder,
  nextOrder,
} from "../../features/product/productSlice";
import ReactToPrint from "react-to-print";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";

function Orders() {
  const dispatch = useDispatch();
  const { orders, singleOrder } = useSelector((store) => store.products);
  const [allOrders, setAllOrders] = useState([...orders]);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const componentRef = useRef();

  const fetch = useCallback(
    async (name, func) => {
      const q = query(collection(db, name), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        dispatch(func(arr));
      });
    },
    [dispatch]
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Admin-Orders";
  }, []);

  useEffect(() => {
    fetch("OrderHistory", fetchOrders);
  }, [fetch]);

  const pageStyle = `
  .customer-order-details {
    padding-top:2rem;
    font-size:1.5rem;
    min-height:100vh;
    display: flex !important;
    flex-direction:column;
    justify-content: space-between !important;
  }
  .top{
    padding:22px 0 ;
  }
  .product {
    width: 700px;
  }
  .size,
  .quality,
  .price {
    width: 100px;
    text-align:center;
  }
  tr{
    display: flex !important;
    justify-content: space-between !important;
  }
  td{
    padding:16px 0 ;
  }
  tfoot tr{
    padding:22px 0 ;
  }
  .btns,.print {
    display: none;
  }

`;

  return (
    <OrderSection>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="stats">
              <div className="stats-card">
                <h1>
                  {day && month && year ? allOrders.length : orders.length}
                </h1>
                <h4>Total Orders</h4>
                <span className="more-icon">
                  <MoreIcon />
                </span>
              </div>
            </div>
            <AdminDates setDay={setDay} setMonth={setMonth} setYear={setYear} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="admin-orders">
              <OrdersSection>
                <OrdersList
                  day={day}
                  month={month}
                  year={year}
                  allOrders={allOrders}
                  setAllOrders={setAllOrders}
                />
              </OrdersSection>
            </div>
          </div>
          <div className="col-md-4">
            {Object.keys(singleOrder).length > 0 ? (
              <div div className="details">
                <article ref={componentRef} className="customer-order-details">
                  <div>
                    <div className="top">
                      <h4>{singleOrder.orderNumber}</h4>
                      <div>
                        <ReactToPrint
                          pageStyle={pageStyle}
                          trigger={() => (
                            <button className="print">
                              <i className="fa-solid fa-print"></i>
                            </button>
                          )}
                          content={() => componentRef.current}
                        />
                      </div>
                      <h5>{singleOrder.fullName}</h5>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th className="product">Product</th>
                          <th className="size">Size</th>
                          <th className="quality">Quanity</th>
                          <th className="price">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleOrder.orderHistory.map((item, index) => {
                          const { name, size, amount, price, discount } = item;
                          return (
                            <tr key={index}>
                              <td className="product">{name} </td>
                              <td className="size">{size}</td>
                              <td className="quality">{amount}</td>
                              <td className="price">
                                GHc{discount > 0 ? discount : price}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="product"></td>
                          <td className="size">Total</td>
                          <td className="quality">{singleOrder.amount}</td>
                          <td className="price">GHc{singleOrder.total}</td>
                        </tr>
                      </tfoot>
                      <tfoot>
                        <tr>
                          <td className="product"></td>
                          <td className="size">Discount:</td>
                          <td className="quality"></td>
                          <td className="price">
                            -GHc{singleOrder.CouponAmount}
                          </td>
                        </tr>
                      </tfoot>
                      <tfoot>
                        <tr>
                          <td className="product"></td>
                          <td className="size">Delivery:</td>
                          <td className="quality"></td>
                          <td className="price">
                            GHc{singleOrder.deliveryFee}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div>
                    <ul className="customer-details">
                      <li>
                        <span>
                          Name:<strong> {singleOrder.fullName}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Number:<strong>{singleOrder.phone}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          City/Town:<strong> {singleOrder.city}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Order number:
                          <strong> {singleOrder.orderNumber}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Date:<strong> {singleOrder.date}</strong>
                        </span>
                      </li>
                      <li>
                        <span>
                          Total:
                          <strong> Ghc {singleOrder.total}</strong>
                        </span>
                      </li>
                      {singleOrder.couponCode ? (
                        <li>
                          <span>
                            Coupon Code:
                            <strong>{singleOrder.couponCode}</strong>
                          </span>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <span>
                          Payment Method:<strong> {singleOrder.payment}</strong>
                        </span>
                      </li>
                    </ul>
                    <div className="btns">
                      <button
                        className="close-btn"
                        onClick={() => dispatch(fetchSingleOrder({}))}
                      >
                        Close
                      </button>
                      <button
                        className="btn-next"
                        onClick={() => dispatch(nextOrder())}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </article>
                <div className="row">
                  <div className="col-md-12">
                    {singleOrder.message ? (
                      <div className="special-request">
                        <h2>Special Request</h2>
                        <p>{singleOrder.message}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </OrderSection>
  );
}

export default Orders;
