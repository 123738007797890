import React, { useState, useEffect } from "react";
import { CheckoutSection } from "../styles/Cart.styled";
import YourOrder from "./YourOrder";
import BillingForm from "./BillingForm";
import PaystackPop from "@paystack/inline-js";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../features/product/productSlice";
import { useNavigate } from "react-router-dom";
import { setCouponCode } from "../../features/reviews/reviewsSlice";
import {
  checkoutSms,
  handlePaymentOnDelivery,
  handlePaymentOnSite,
} from "../../utils/checkoutFunctions";

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.user);
  const { total, amount, cartItems } = useSelector((store) => store.products);
  const { couponCode, isCouponCode } = useSelector((store) => store.reviews);

  const [couponMsg, setCouponMsg] = useState("");
  const [msg, setMsg] = useState(
    "Note: We only accept payment on site for deliveries outside the Greater Accra region."
  );
  const [deliveryFee, setDeliveryFee] = useState(0);

  const formik = useFormik({
    initialValues: {
      fullName: user?.fullName || "",
      region: user?.region || "",
      city: user?.city || "",
      phone: user?.phone || "",
      email: user?.userEmail || "",
      message: "",
      payment: "Payment on site",
    },
    onSubmit: (values) => {
      if (formik.values.payment === "Payment on site") {
        const paystack = new PaystackPop();
        paystack.newTransaction({
          key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
          amount: (total + deliveryFee - couponCode.amount) * 100,
          email: formik.values.email,
          fullName: formik.values.fullName,
          onSuccess(transaction) {
            handlePaymentOnSite(
              cartItems,
              formik,
              transaction,
              dispatch,
              total,
              deliveryFee,
              couponCode,
              user
            );
            checkoutSms(formik);

            setTimeout(() => {
              dispatch(clearCart());
            }, 2000);
            navigate("/order-status");
          },
          onCancel() {
            alert("Cancelled");
          },
        });
      } else if (formik.values.payment === "Payment on delivery") {
        handlePaymentOnDelivery(
          cartItems,
          formik,
          dispatch,
          total,
          deliveryFee,
          couponCode,
          user
        );
        dispatch(clearCart());
        navigate("/order-status");
      }
    },
    validate: (values) => {
      let errors = {};

      if (!values.fullName) {
        errors.fullName = "Full Name is Required";
      }
      if (!values.region) {
        errors.region = "Please Select your Region";
      }
      if (!values.city) {
        errors.city = "Please Enter your City";
      }
      if (!values.phone) {
        errors.phone = "Phone is Required";
      } else if (
        !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(values.phone) ||
        values.phone.length !== 10
      ) {
        errors.phone = "Please Enter a Valid Phone Number";
      }

      if (!values.email) {
        errors.email = "Email is Required";
      } else if (
        !/^[A-Z0-9.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Enter a Valid Email";
      }
      if (!values.payment) {
        errors.payment = "Please Select a Payment Method";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (
      formik.values.region === "Greater Accra Region" ||
      formik.values.region === ""
    ) {
      setMsg("NB: Payment on delivery would attract a delivery fee.");
    } else {
      setMsg(
        "Note: We do not accept payment on delivery for orders outside the Greater Accra region."
      );
    }

    if (formik.values.payment === "Payment on site" && amount > 1) {
      setDeliveryFee(0);
    } else {
      setDeliveryFee(35);
    }
  }, [formik.values.region, formik.values.payment, amount]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Checkout";
    if (cartItems.length < 2) {
      dispatch(setCouponCode({ code: "", amount: "0" }));
      setCouponMsg(
        "Coupon code discount is only available when you purchase 2 or more items"
      );
    }
  }, [cartItems.length, dispatch]);

  return (
    <>
      <CheckoutSection>
        <div className="container">
          <div className="row">
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                1
              </span>
              <h3 style={{ color: "black" }}>SHOPPING CART</h3>
            </div>
            <div className="col-md-4 stages">
              <span
                style={{
                  backgroundColor: "#efa893",
                  border: "0",
                  color: "white",
                }}
              >
                2
              </span>
              <h3 style={{ color: "black" }}>CHECKOUT</h3>
            </div>
            <div className="col-md-4 stages">
              <span>3</span>
              <h3>ORDER STATUS</h3>
            </div>
          </div>
          <form className="checkout" onSubmit={formik.handleSubmit}>
            {/* billing details  */}
            <div className="billing-details">
              <h6>BILLING DETAILS</h6>
              <div className="pink-line"></div>
              <BillingForm formik={formik} />
              <h6>{msg}</h6>
              <div className="delivery-note">
                DELIVERY WILL BE MADE DAY AFTER THE ORDER IS PLACED FOR ORDERS
                WITHIN ACCRA. FOR OTHER REGIONS, A MAXIMUM OF 3 DAYS DELIVERY
                PERIOD.
              </div>
            </div>
            {/* end of billing details  */}
            <YourOrder
              isCouponCode={isCouponCode}
              couponMsg={couponMsg}
              deliveryFee={deliveryFee}
            />
          </form>
        </div>
      </CheckoutSection>
    </>
  );
}

export default Checkout;
