import React, { useState, useEffect } from "react";
import { close } from "../../icons";
import { AddProductSection } from "../styles/AddProduct.styled";
import UploadImages from "./UploadImages";
import { saveProduct } from "../../utils/firebaseFunctions";
import { useSelector } from "react-redux";
import { useCallback } from "react";

function AddNewProduct({
  setOpen,
  product,
  setProduct,
  defaultState,
  defaultStock,
  stock,
  setStock,
}) {
  const { allProducts } = useSelector((store) => store.products);
  const { categories, collections } = useSelector((store) => store.reviews);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [populatedItemId, setPopulatedItemId] = useState("");

  useEffect(() => {
    setImageUrls(product.images);
  }, [product.images]);

  const setExisting = useCallback(() => {
    if (populatedItemId) {
      let temp = allProducts.find((item) => item.id === populatedItemId);
      setProduct({
        ...product,
        name: temp.name,
        category: temp.category,
        collection: temp.collection,
        description: temp.description,
        features: temp.features,
        tags: temp.tags,
      });
    }
  }, [allProducts, populatedItemId, setProduct]);

  useEffect(() => {
    setExisting();
  }, [setExisting]);

  // useEffect(() => {
  //   populate();
  // }, [populate]);

  const handlePopulate = (e) => {
    setPopulatedItemId(e.target.value);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProduct({ ...product, [name]: value });
  };
  const handleStockChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setStock({ ...stock, [name]: value * 1 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      product.name !== ""
      //   product.category !== "" ||
      //   product.description !== "" ||
      //   product.features !== "" ||
      //   product.tags !== "" ||
      //   product.price !== "" ||
      //   imageUrls.length > 0
    ) {
      saveProduct(product, stock, imageUrls, product.id);
      setMsg("Product Uploaded Successfully");
      setProduct(defaultState);
      setStock(defaultStock);
      setImageUrls([]);
      setPopulatedItemId("");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } else {
      setMsg("Product name is required");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setProduct(defaultState);
    setStock(defaultStock);
    setPopulatedItemId("");
  };

  return (
    <>
      <AddProductSection>
        <span className="close-modal" onClick={handleClose}>
          {close}
        </span>
        <h2 className="text-center">Add A New Product</h2>
        <hr />
        <div className="msg">
          <h3>{msg}</h3>
        </div>
        <form>
          <div className="form">
            <div className="left">
              <h3>Populate from existing Product</h3>
              <select
                id="populate"
                value={product.name}
                onChange={handlePopulate}
              >
                <option value="">Select an existing product</option>
                {allProducts?.map((item) => {
                  const { id, name } = item;
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
              <h3>Details</h3>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter product name"
                name="name"
                value={product.name}
                onChange={handleChange}
              />
              <label htmlFor="category">Category</label>
              <select
                id="category"
                name="category"
                value={product.category}
                onChange={handleChange}
              >
                <option value="">Select product category</option>
                {categories?.map((item) => {
                  const { id, cat } = item;
                  return (
                    <option key={id} value={cat}>
                      {cat}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="collection">Collection</label>
              <select
                id="collection"
                name="collection"
                value={product.collection}
                onChange={handleChange}
              >
                <option value="">Select product collection</option>
                {collections?.map((item) => {
                  const { id, coll } = item;
                  return (
                    <option key={id} value={coll}>
                      {coll}
                    </option>
                  );
                })}
              </select>
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                cols="30"
                rows="5"
                placeholder="Enter product description (Optional)"
                name="description"
                value={product.description}
                onChange={handleChange}
              ></textarea>
              <label htmlFor="features">Highlighted Features</label>
              <textarea
                id="features"
                cols="30"
                rows="5"
                placeholder="Enter product features delimited by a pipe symbol"
                name="features"
                value={product.features}
                onChange={handleChange}
              ></textarea>
              <br />
              <div className="checkboxes">
                <span>
                  <label>New Arrivals</label>
                  <select
                    name="newArrivals"
                    value={product.newArrivals}
                    onChange={handleChange}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </span>

                <span>
                  <label>Sale Image</label>
                  <select
                    name="mainSale"
                    value={product.mainSale}
                    onChange={handleChange}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </span>

                <span>
                  <label>Collection Image</label>
                  <select
                    name="mainCollection"
                    value={product.mainCollection}
                    onChange={handleChange}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </span>
              </div>
              <label htmlFor="tags">Tags</label>
              <input
                type="text"
                id="tags"
                placeholder="jeans,"
                name="tags"
                value={product.tags}
                onChange={handleChange}
              />
            </div>
            <div className="right">
              <UploadImages
                id={product.id}
                images={images}
                setImages={setImages}
                imageUrls={imageUrls}
                setImageUrls={setImageUrls}
                isImageLoading={isImageLoading}
                setIsImageLoading={setIsImageLoading}
              />
              <label htmlFor="price">Price</label>
              <div className="discount">
                <input
                  type="number"
                  id="price"
                  placeholder="50.00"
                  name="price"
                  value={product.price}
                  onChange={handleChange}
                />
                <span>Ghc</span>
              </div>
              <br />
              <label htmlFor="discount">Discounted Amount</label>
              <div className="discount">
                <input
                  type="number"
                  id="discount"
                  placeholder="35.00"
                  name="discount"
                  value={product.discount}
                  onChange={handleChange}
                />
                <span>Ghc</span>
              </div>
              <div className="stock">
                <h3>Stock</h3>
                <div className="stock-icons"></div>
              </div>
              <div>
                <label>Descripion</label>
                <label>Quality</label>
              </div>
              <div className="stock-desc-quan">
                <div className="sizes">
                  <input type="text" placeholder="XS" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="XS"
                    value={stock.XS}
                    onChange={handleStockChange}
                  />
                </div>
                <div className="sizes">
                  <input type="text" placeholder="Small" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="Small"
                    value={stock.Small}
                    onChange={handleStockChange}
                  />
                </div>
                <div className="sizes">
                  <input type="text" placeholder="Medium" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="Medium"
                    value={stock.Medium}
                    onChange={handleStockChange}
                  />
                </div>
                <div className="sizes">
                  <input type="text" placeholder="Large" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="Large"
                    value={stock.Large}
                    onChange={handleStockChange}
                  />
                </div>
                <div className="sizes">
                  <input type="text" placeholder="XL" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="XL"
                    value={stock.XL}
                    onChange={handleStockChange}
                  />
                </div>
                <div className="sizes">
                  <input type="text" placeholder="XXL" disabled />
                  <input
                    type="number"
                    placeholder="0"
                    name="XXL"
                    value={stock.XXL}
                    onChange={handleStockChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </form>

        <div className="msg">
          <h3>{msg}</h3>
        </div>
        <div className="form-btns">
          <button className="close-btn" onClick={handleClose}>
            Close
          </button>
          <button className="add-btn" type="submit" onClick={handleSubmit}>
            Add Product
          </button>
        </div>
      </AddProductSection>
    </>
  );
}

export default AddNewProduct;
