import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cart, close } from "../../icons";
import { CartOverlaySection } from "../styles/Cart.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItem,
  closeOverlay,
  handleOverlay,
} from "../../features/product/productSlice";

function CartOverlay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems, amount } = useSelector((store) => store.products);
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));

    dispatch(handleOverlay());
  }, [cartItems, dispatch]);

  return (
    <CartOverlaySection>
      <div className="top">
        <div className="cartIcon">
          <Link to="/cart">
            <span>{cart}</span>
          </Link>
          <span className="cart-num">{amount}</span>
        </div>
        <span className="close-btn" onClick={() => dispatch(closeOverlay())}>
          {close}
        </span>
      </div>
      {cartItems?.map((item, index) => {
        const { images, name, price, discount, size, amount } = item;
        return (
          <article key={index} className="cartoverlay-item">
            <img src={images[0]} alt="" />
            <div className="cartoverlay-item-info">
              <h6>{name}</h6>
              <p>Size: {size}</p>
              <p>Quantity: {amount}</p>
              <h5>GHS{discount > 0 ? discount : price}</h5>
            </div>
            <span
              className="remove-icon"
              onClick={() => dispatch(removeItem(item))}
            >
              {close}
            </span>
          </article>
        );
      })}

      <div className="cart-btns">
        <button
          className="checkout"
          onClick={() => {
            navigate("/checkout");
            dispatch(closeOverlay());
          }}
        >
          Checkout
        </button>
        <button className="continue" onClick={() => dispatch(closeOverlay())}>
          Continue shopping
        </button>
      </div>
    </CartOverlaySection>
  );
}

export default CartOverlay;
