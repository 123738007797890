import styled from "styled-components";

export const MyFooter = styled.section`
  & a {
    text-decoration: none;
  }
  footer {
    background-color: #f5cbae;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-mobile {
    display: none;
  }
  .footer {
    height: 20rem;
    width: 80rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-links {
    list-style: none;
    width: 20%;
    padding: 0;
  }
  .footer-links a {
    color: black;
  }
  .footer-logo {
    height: 5rem;
  }
  .newsletter {
    width: 40%;
  }

  .newsletter input {
    outline: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 2rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .newsletter button {
    height: 2rem;
    width: 6rem;
    border: 1px solid black;
    background-color: transparent;
    color: black;
  }

  .copyright {
    background-color: white;
    margin-bottom: 0;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyright p {
    margin-bottom: 0;
  }
  @media (max-width: 1360px) {
    .footer {
      display: none;
    }
    .footer-mobile {
      width: 90%;
      display: flex;
      flex-direction: column;
    }
    .footer-mobile a {
      width: 19rem;
    }
    .footer-logo {
      margin: 2rem 0;
      height: 3rem;
      width: 18rem;
    }
    .newsletter {
      width: 60%;
    }
    .footer-links {
      margin: 2rem 0;
      width: 80%;
    }
  }
  .newsletter-msg {
    height: 2rem;
  }
  @media (max-width: 700px) {
    .footer-logo {
      margin: 2rem 0;
      height: 3rem;
      width: 15rem;
    }
    .newsletter {
      width: 90%;
    }
  }
`;
