import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ children }) {
  const { user } = useSelector((store) => store.user);
  if (
    user?.role?.includes("admin") &&
    process.env.REACT_APP_ADMIN_EMAIL.includes(user?.userEmail)
  ) {
    return children;
  }
  return <Navigate to="/" />;
}

export default ProtectedRoute;
