import { createSlice } from "@reduxjs/toolkit";
// import dayjs from "dayjs";
import { fetchCart } from "../../utils/firebaseFunctions";
const cartData = fetchCart();

const initialState = {
  products: [],
  allProducts: [],
  cartItems: cartData || [],
  amount: 0,
  total: 0,
  isOverlayOpen: false,
  nextID: "",
  category: [],
  activeCategory: "sets",
  sale: [],
  newArrivals: [],
  orders: [],
  singleOrder: [],
  searchedProducts: [],
  searchField: "",
  filterValue: "",
  isSearch: false,
  isLoading: true,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    fetchProducts: (state, action) => {
      state.products = action.payload;
      state.sale = action.payload.filter((item) => item.discount > 0);
      state.newArrivals = action.payload.filter(
        // (item) => dayjs(item.date) >= dayjs().subtract(1, "month")
        (item) => item.newArrivals === true || item.newArrivals === "true"
      );
      if (state.products.length > 0) {
        state.isLoading = false;
      }
    },
    getAllProducts: (state, action) => {
      state.allProducts = action.payload;
      if (state.products.length > 0) {
        state.isLoading = false;
      }
    },
    clearCart: (state) => {
      state.cartItems = [];
    },
    addToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].amount = action.payload.amount;
      } else {
        const tempItem = { ...action.payload };
        state.cartItems.push(tempItem);
        state.isOverlayOpen = true;
      }
    },
    removeItem: (state, action) => {
      const itemId = action.payload.id;
      const itemSize = action.payload.size;
      state.cartItems = state.cartItems.filter(
        (item) => item.size !== itemSize || item.id !== itemId
      );
    },
    increase: (state, action) => {
      const cartItem = state.cartItems.find(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      cartItem.amount = cartItem.amount + 1;
    },
    decrease: (state, action) => {
      const cartItem = state.cartItems.find(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      cartItem.amount = cartItem.amount - 1;
    },
    setItemAmount: (state, action) => {
      const cartItem = state.cartItems.find(
        (item) =>
          item.id === action.payload.id && item.size === action.payload.size
      );
      cartItem.amount = cartItem[action.payload.size];
    },
    calculateTotals: (state) => {
      let amount = 0;
      let total = 0;
      state.cartItems.forEach((item) => {
        if (item.discount > 0) {
          amount += item.amount;
          total += item.amount * item.discount;
        } else {
          amount += item.amount;
          total += item.amount * item.price;
        }
      });
      state.amount = amount;
      state.total = total;
    },
    handleOverlay: (state) => {
      if (state.cartItems.length === 0) {
        state.isOverlayOpen = false;
      }
    },
    closeOverlay: (state) => {
      state.isOverlayOpen = false;
    },
    fetchCategory: (state, action) => {
      state.category = state.products.filter(
        (item) =>
          item.category === action.payload || item.collection === action.payload
      );
      if (state.category.length > 0) {
        state.isLoading = false;
      }
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    setID: (state, action) => {
      state.nextID = action.payload;
    },
    nextProduct: (state, action) => {
      let temp = state.products.findIndex((item) => item.id === action.payload);
      if (temp === state.products.length - 1) {
        temp = 0;
      }
      state.nextID = state.products[temp + 1].id;
    },
    nextOrder: (state) => {
      let temp = state.orders.findIndex(
        (item) => item.orderNumber === state.singleOrder.orderNumber
      );
      if (temp === state.orders.length - 1) {
        temp = 0;
      }
      state.singleOrder = state.orders[temp + 1];
    },
    fetchOrders: (state, action) => {
      state.orders = action.payload;
    },
    fetchSingleOrder: (state, action) => {
      state.singleOrder = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setFilter: (state, action) => {
      state.filterValue = action.payload;
    },
  },
});
export const {
  fetchProducts,
  getAllProducts,
  clearCart,
  addToCart,
  increase,
  removeItem,
  decrease,
  calculateTotals,
  setItemAmount,
  handleOverlay,
  closeOverlay,
  fetchOrders,
  nextProduct,
  setID,
  fetchSingleOrder,
  setActiveCategory,
  setSearchField,
  setFilter,
  fetchCategory,
  nextOrder,
} = productSlice.actions;

export default productSlice.reducer;
