import styled from "styled-components";

export const AboutUsSection = styled.section`
  .img-container {
    box-shadow: 15px 15px #f5cbae;
  }
  h1 {
    font-family: TestFoundersGrotesk-Regular, sans-serif;
    font-weight: 600;
    color: black;
  }
  // .first-text {
  //   font-family: SaolDisplay;
  //   font-size: 70px;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.04;
  //   letter-spacing: -1px;
  //   text-align: center;
  //   color: #000;
  //   max-width: 1075px;
  //   padding: 0 50px;
  //   margin: 30px auto auto;
  // }
  h3 {
    font-family: TestFoundersGrotesk-Regular, sans-serif;
    font-weight: 600;
    color: black;
    margin-bottom: 1rem;
  }
  h4 {
    font-family: TestFoundersGrotesk-Regular, sans-serif;
    // font-weight: 600;
    color: black;
    margin-top: 6rem;
    margin-bottom: 1rem;
  }
  button {
    font-size: 1.1rem;
    border: 0;
    background-color: #f5cbae;
    height: 3.5rem;
    width: 12rem;
    margin: 2rem 0;
  }
  p {
    font-family: TestFoundersGrotesk-Regular, sans-serif;
    // font-size: 14px;
    ont-size: 14px;
    font-weight: 400;
    color: black;
  }
  p span {
    font-weight: 900;
    color: black;
  }
  .return-policy {
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
  .return-policy li {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    color: #efa893;
  }
  .return-policy li h3 {
    margin-top: 2rem;
  }
  .return-policy li span {
    color: black;
  }
  .mob {
    display: none;
  }
  .nomob {
    display: flex;
  }
  @media (max-width: 767px) {
    .img-container {
      box-shadow: 0 0;
    }
    h4 {
      margin-top: 1rem;
    }
    .main h1 {
      margin-top: 1rem;
    }
    .main p {
      margin: 0 !important;
    }
    .mob {
      display: flex;
    }
    .nomob {
      display: none;
    }
    .img-footer {
      background-color: #f5cbae;
      padding: 1rem;
      margin-bottom: 3rem;
    }
  }
`;
