import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const initialState = {
  reviews: [],
  coupons: [],
  couponCode: { code: "", amount: "0" },
  isCouponCode: false,
  categories: [],
  collections: [],
  sessions: [],
  sessionCount: [],
  userSession: [],
  orderCount: [],
  isLoading: true,
};

export const reviewsFetch = createAsyncThunk(
  "reviews/reviewsFetch",
  async (thunkAPI) => {
    try {
      const items = await getDocs(
        query(collection(db, "Reviews"), orderBy("name", "desc"))
      );
      return items.docs.map((doc) => doc.data());
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    fetchCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    fetchCategories: (state, action) => {
      state.categories = action.payload;
    },
    fetchCollections: (state, action) => {
      state.collections = action.payload;
    },
    getAllSessions: (state, action) => {
      state.sessions = action.payload;
    },
    fetchSessionCount: (state, action) => {
      state.sessionCount = action.payload;
    },
    setUserSession: (state, action) => {
      state.userSession = action.payload;
    },
    fetchOrderCount: (state, action) => {
      state.orderCount = action.payload;
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    setIsCouponCode: (state, action) => {
      state.isCouponCode = action.payload;
    },
  },
  extraReducers: {
    [reviewsFetch.pending]: (state) => {
      state.isLoading = true;
    },
    [reviewsFetch.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reviews = action.payload;
    },
    [reviewsFetch.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },
  },
});
export const {
  fetchCoupons,
  setCouponCode,
  fetchCategories,
  fetchCollections,
  getAllSessions,
  setUserSession,
  setIsCouponCode,
  fetchSessionCount,
  fetchOrderCount,
} = reviewsSlice.actions;

export default reviewsSlice.reducer;
