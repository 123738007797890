import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoreIcon } from "../../icons";
import AdminDates from "./AdminDates";
import {
  fetchOrderCount,
  setUserSession,
} from "../../features/reviews/reviewsSlice";
import {
  // getSessionCount,
  getOrderCount,
  getUserSession,
} from "../../utils/firebaseFunctions";
import dayjs from "dayjs";

function Dashboard() {
  const dispatch = useDispatch();
  const { orderCount, userSession } = useSelector((store) => store.reviews);

  let orders = 0;
  let usersessions = 0;
  orderCount.forEach((item) => {
    orders += item.count;
  });
  userSession.forEach((item) => {
    usersessions += item.count;
  });

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [specificUserSessions, setSpecificUserSessions] = useState({});
  const [specificOrders, setSpecificOrders] = useState({});

  const addToStore = useCallback(
    async (func, reduxFunc) => {
      await func().then((data) => {
        dispatch(reduxFunc(data));
      });
    },
    [dispatch]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Admin-Dashboard";
    if (day && month && year) {
      setSpecificUserSessions(
        userSession?.find((item) =>
          dayjs(item.date).isSame(dayjs(`${day} ${month}, ${year}`))
        )
      );
      setSpecificOrders(
        orderCount?.find((item) =>
          dayjs(item.date).isSame(dayjs(`${day} ${month}, ${year}`))
        )
      );
    }
  }, [day, month, year, orderCount, userSession]);

  useEffect(() => {
    // addToStore(getSessionCount, fetchSessionCount);
    addToStore(getOrderCount, fetchOrderCount);
    addToStore(getUserSession, setUserSession);
  }, [dispatch, addToStore]);

  if (day && month && year) {
    return (
      <section className="dashboard">
        <div className="stats">
          <div className="stats-card">
            <h1>
              {" "}
              {specificOrders?.count || 0}{" "}
              {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
            </h1>
            <h4>Users</h4>
            <span className="more-icon">
              <MoreIcon />
            </span>
          </div>
          <div className="stats-card">
            <h1>
              {(
                (specificOrders?.count / specificUserSessions?.count) *
                100
              ).toFixed(1) === "NaN"
                ? 0
                : (
                    (specificOrders?.count / specificUserSessions?.count) *
                    100
                  ).toFixed(1)}
              %{" "}
              {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
            </h1>
            <h4>Conversion rate</h4>
            <span className="more-icon">
              <MoreIcon />
            </span>
          </div>
          <div className="stats-card">
            <h1>
              {specificUserSessions?.count || 0}{" "}
              {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
            </h1>
            <h4>Sessions</h4>
            <span className="more-icon">
              <MoreIcon />
            </span>
          </div>
        </div>
        <div className="admin-dates">
          <AdminDates setDay={setDay} setMonth={setMonth} setYear={setYear} />
        </div>
        {/* <SalesChart /> */}
      </section>
    );
  }

  return (
    <section className="dashboard">
      <div className="stats">
        <div className="stats-card">
          <h1>
            {orders}{" "}
            {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
          </h1>
          <h4>Users</h4>
          <span className="more-icon">
            <MoreIcon />
          </span>
        </div>
        <div className="stats-card">
          <h1>
            {((orders / usersessions) * 100).toFixed(1)}%{" "}
            {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
          </h1>
          <h4>Conversion rate</h4>
          <span className="more-icon">
            <MoreIcon />
          </span>
        </div>
        <div className="stats-card">
          <h1>
            {usersessions}{" "}
            {/* <small>
              (+12%
              <LongArrowDown />)
            </small> */}
          </h1>
          <h4>Sessions</h4>
          <span className="more-icon">
            <MoreIcon />
          </span>
        </div>
      </div>
      <div className="admin-dates">
        <AdminDates setDay={setDay} setMonth={setMonth} setYear={setYear} />
      </div>
      {/* <SalesChart /> */}
    </section>
  );
}

export default Dashboard;
