import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

function SampleSaleSwiper() {
  const { sale } = useSelector((store) => store.products);
  return (
    <>
      <div className="sample-sale-slide">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          breakpoints={{
            300: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 100,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {sale?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to="/sale">
                  <img src={item.images[0]} alt="img" />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default SampleSaleSwiper;
