import React, { useEffect } from "react";
import { AboutUsSection } from "./styles/AboutUs.styled";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | About Us";
  }, []);

  return (
    <AboutUsSection>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center main">
            <h1>About Us</h1>
            <p className="mb-5 mt-3 first-text">
              You can have anything you want in life if you dress for it.
            </p>
          </div>
          <div className="col-md-5 mb-5">
            <div className="img-container">
              <img src="./images/3.png" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6 mx-auto my-auto">
            <h3>LMK is here!</h3>
            <p>
              Establised in April 2022, we are a fashion brand here to provide
              the most stylish pieces for all your special occasions.
            </p>
            <h4>Stick with us!</h4>
            <Link to="/products/dresses">
              <button>
                START SHOPPING <HiOutlineArrowRight />
              </button>
            </Link>
          </div>
        </div>
        <div className="row nomob">
          <div className="col-md-4 my-auto">
            <h1>Our Mission</h1>
            <p>
              To be your go to brand for fashionable and memorable clothes and
              accessories.
            </p>
          </div>
          <div className="col-md-5 mb-5 mx-auto">
            <div className="img-container mt-5">
              <img src="./images/8.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row mob">
          <div className="col-md-5 mb-5 mx-auto">
            <div className="img-container">
              <img src="./images/8.png" alt="" className="img-fluid" />
            </div>
            <div className="img-footer">
              <h1>Our Mission</h1>
              <p>
                To be your go to brand for fashionable and memorable clothes and
                accessories.
              </p>
            </div>
          </div>
        </div>
      </div>
    </AboutUsSection>
  );
}

export default AboutUs;
