import React, { useState, useEffect } from "react";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { MdArrowForwardIos } from "react-icons/md";
import PinkBar from "./PinkBar";
import AlsoCheckTheseOut from "./AlsoCheckTheseOut";
import { SingleProductContainer } from "./styles/SingleProduct.styles";
import SingleProductImages from "./SingleProductImages";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  addToCart,
  nextProduct,
  setID,
} from "../features/product/productSlice";
import ReviewForm from "./ReviewForm";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SingleProduct() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, nextID, isLoading } = useSelector(
    (store) => store.products
  );

  const [singleItem, setSingleItem] = useState({});
  const [size, setSize] = useState("");
  const [amount, setAmount] = useState(1);
  const [alert, setAlert] = useState("");
  const [addDisabled, setIsAddDisabled] = useState(false);
  const [isreviews, setIsreviews] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Lomokie | Product";
    setAmount(1);
    setSize("");
    setIsreviews(false);
  }, [id]);

  useEffect(() => {
    dispatch(setID(""));
    setSingleItem(products.find((item) => item.id === id));
    if (nextID) {
      navigate(`/product/${nextID}`);
    }
  }, [id, products, nextID, dispatch, navigate]);

  const addItem = () => {
    if (!size) {
      setAlert("Please select a size");
    } else {
      setAlert("");
      dispatch(addToCart({ ...singleItem, size, amount }));
    }
  };

  const selectSize = (el) => {
    setSize(el);
    if (singleItem[el] < 1) {
      setIsAddDisabled(true);
      setAmount(0);
    } else if (singleItem[el] > 0) {
      setIsAddDisabled(false);
      setAmount(1);
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="row m-3">
          <div className="col-md-5">
            <Skeleton height={300} width={300} />
          </div>
          <article className="col-md-6">
            <Skeleton height={60} width={150} className="mb-5" />
            <Skeleton height={30} width={400} />
            <Skeleton height={30} width={150} className="mt-3" />
            <Skeleton height={30} width={400} />
            <div style={{ display: "flex" }}>
              <Skeleton height={50} width={70} className="my-3 mr-3" />
              <Skeleton height={50} width={70} className="my-3 mr-3" />
              <Skeleton height={50} width={70} className="my-3 mr-3" />
              <Skeleton height={50} width={70} className="my-3 mr-3" />
            </div>
            <Skeleton height={60} width={400} className="mt-5" />
          </article>
        </div>
      </div>
    );
  }

  return (
    <>
      <SingleProductContainer>
        <div className="container">
          {singleItem ? (
            <div className="row mx-auto single-product">
              <div className="col-md-5 single-product-img">
                <div className="mobile-next">
                  <IoIosArrowDroprightCircle
                    onClick={() => dispatch(nextProduct(singleItem.id))}
                    style={{
                      fontSize: "2.5rem",
                      color: "#efa893",
                      cursor: "pointer",
                    }}
                  />
                  <span
                    onClick={() => dispatch(nextProduct(singleItem.id))}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  >
                    Next item
                  </span>
                </div>
                <SingleProductImages images={singleItem.images} />
              </div>
              <div className="col-md-6">
                <div className="single-product-desc">
                  <div className="next">
                    <IoIosArrowDroprightCircle
                      onClick={() => dispatch(nextProduct(singleItem.id))}
                      style={{
                        fontSize: "3rem",
                        color: "#efa893",
                        cursor: "pointer",
                      }}
                    />
                    <span
                      onClick={() => dispatch(nextProduct(singleItem.id))}
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    >
                      Next item
                    </span>
                  </div>
                  <br />
                  <div>
                    <h4 className="name">{singleItem.name}</h4>
                    {singleItem.discount > 0 ? (
                      <h2 className="product-price">
                        <span>GHc {singleItem.price}</span> GHc{" "}
                        {singleItem.discount}
                      </h2>
                    ) : (
                      <h2 className="product-price">GHc {singleItem.price}</h2>
                    )}
                  </div>
                  <br />
                  <div className="size-text">
                    <h4>Size:</h4>
                    <Link to="/size-chart">
                      <h4 className="size-chart-text">
                        Size Chart
                        <MdArrowForwardIos style={{ color: "#efa893" }} />
                      </h4>
                    </Link>
                  </div>
                  <div className="size-btn">
                    {singleItem.XXL > 0 ? (
                      <button
                        onClick={() => selectSize("XS")}
                        id={singleItem.XS < 1 ? "disable-size" : ""}
                        className={size === "XS" ? "active-size" : ""}
                      >
                        XS
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => selectSize("Small")}
                      id={singleItem.Small < 1 ? "disable-size" : ""}
                      className={size === "Small" ? "active-size" : ""}
                    >
                      Small
                    </button>
                    <button
                      onClick={() => selectSize("Medium")}
                      id={singleItem.Medium < 1 ? "disable-size" : ""}
                      className={size === "Medium" ? "active-size" : ""}
                    >
                      Medium
                    </button>
                    <button
                      onClick={() => selectSize("Large")}
                      id={singleItem.Large < 1 ? "disable-size" : ""}
                      className={size === "Large" ? "active-size" : ""}
                    >
                      Large
                    </button>
                    <button
                      onClick={() => selectSize("XL")}
                      id={singleItem.XL < 1 ? "disable-size" : ""}
                      className={size === "XL" ? "active-size" : ""}
                    >
                      XL
                    </button>
                    {singleItem.XXL > 0 ? (
                      <button
                        onClick={() => selectSize("XXL")}
                        className={size === "XXL" ? "active-size" : ""}
                      >
                        XXL
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <br />
                  <h5>{alert}</h5>
                  <div className="item-quantity-table">
                    <button>
                      <p
                        onClick={() => {
                          if (amount === 1) {
                            setAmount(1);
                            return;
                          } else {
                            setAmount(amount - 1);
                          }
                        }}
                      >
                        -
                      </p>
                    </button>
                    <p style={{ width: "4rem" }}>{amount}</p>
                    <button>
                      <p
                        onClick={() => {
                          if (
                            amount === singleItem[size] ||
                            amount > singleItem[size]
                          ) {
                            setAmount(singleItem[size]);
                            return;
                          } else {
                            setAmount(amount + 1);
                          }
                        }}
                      >
                        +
                      </p>
                    </button>
                  </div>
                  <br />
                  <div className={addDisabled ? "disabledbtn" : "addtocartbtn"}>
                    <button
                      onClick={() => {
                        addItem();
                      }}
                    >
                      <h4> Add to Cart</h4>
                    </button>
                    <div className={addDisabled ? "out" : "hide-out"}>
                      <p>Out Of Stock</p>
                    </div>
                  </div>
                  <br />
                  <div className="aboutProduct">
                    <p>{singleItem.description}</p>
                    <ul className="size-list">
                      {singleItem.features?.split("\n").map((item, index) => {
                        return (
                          <li key={index}>
                            <span>{item}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <PinkBar />

        <section className="container">
          <div className="row">
            <div className="col-md-12 write-review">
              <button onClick={() => setIsreviews(!isreviews)}>
                Write A Review
              </button>
            </div>
          </div>
          <div className="row">
            <ReviewForm
              isreviews={isreviews}
              productName={singleItem?.length < 1 ? "" : singleItem?.name}
              productId={singleItem?.length < 1 ? "" : singleItem?.id}
            />
          </div>
        </section>
        {isreviews ? <PinkBar /> : ""}
        {/* <PinkBar /> */}
        <AlsoCheckTheseOut paramsID={id} />
      </SingleProductContainer>
    </>
  );
}

export default SingleProduct;
