import React, { useState, useCallback, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { AdminSection } from "../styles/Admin.styled";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../../features/user/userSlice";
import { getAllProducts } from "../../features/product/productSlice";
import {
  fetchCategories,
  fetchCollections,
  fetchCoupons,
} from "../../features/reviews/reviewsSlice";
import { bars, close } from "../../icons";
import { db } from "../../firebaseConfig";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

function Admin() {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const [isSidebar, setIsSidebar] = useState(false);

  const fetch = useCallback(
    async (name, func) => {
      const q = query(collection(db, name), orderBy("timestamp", "desc"));
      onSnapshot(q, (querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        dispatch(func(arr));
      });
    },
    [dispatch]
  );

  useEffect(() => {
    fetch("Products", getAllProducts);
    fetch("Coupons", fetchCoupons);
    fetch("Category", fetchCategories);
    fetch("Collection", fetchCollections);
  }, [fetch]);

  const toggleSidebar = () => setIsSidebar(!isSidebar);

  return (
    <>
      <AdminSection>
        <span className="hamburger" onClick={toggleSidebar}>
          {bars}
        </span>
        <aside className={isSidebar ? "admin-sidebar" : "hide-admin-sidebar"}>
          <span className="close" onClick={toggleSidebar}>
            {close}
          </span>
          <Link to="/">
            <img src="/images/logo01.png" alt="logo" className="logo" />
          </Link>
          <ul className="dashboard-links">
            <Link to="/admin" onClick={toggleSidebar}>
              <li>Dashboard</li>
            </Link>
            <Link to="/admin/orders" onClick={toggleSidebar}>
              <li>Orders</li>
            </Link>
            <Link to="/admin/products" onClick={toggleSidebar}>
              <li>Products</li>
            </Link>
            <Link to="/admin/categories" onClick={toggleSidebar}>
              <li>Categories</li>
            </Link>
            <Link to="/admin/coupons" onClick={toggleSidebar}>
              <li>Coupons</li>
            </Link>
            <li onClick={() => dispatch(clearUser())}>Logout</li>
          </ul>
        </aside>
        <section className="admin-section">
          <div className="profile">
            <span className="profile-icon"></span>
            <h5>Hi, {user?.firstName}</h5>
          </div>
          <Outlet />
        </section>
      </AdminSection>
    </>
  );
}

export default Admin;
