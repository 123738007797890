import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function YourOrder({ deliveryFee, couponMsg, isCouponCode }) {
  const { cartItems, total } = useSelector((store) => store.products);
  const { couponCode } = useSelector((store) => store.reviews);
  return (
    <section id="your-order">
      <div className="your-order">
        <h6>YOUR ORDER</h6>
        <div className="order-pink-line"></div>
        {cartItems?.map((item, index) => {
          const { name, price, size, discount, amount } = item;
          return (
            <article key={index} className="your-orders">
              <div className="your-order-item">
                <div className="your-order-item-info">
                  <h5>{name}</h5>
                  <p>Size {size}</p>
                  <p>Quantity {amount}</p>
                </div>
              </div>
              <div className="order-price">
                <h6>Ghc{discount > 0 ? discount : price}</h6>
              </div>
            </article>
          );
        })}

        <div className="order-pink-line"></div>
        <div className="totals">
          <div>
            <h6 className="text-left">Subtotal</h6>
            <h6 className="text-right">Ghc{total}</h6>
          </div>
          {deliveryFee > 0 ? (
            <div>
              <h6 className="text-left">Delivery fee</h6>
              <h6 className="text-right">Ghc{deliveryFee}</h6>
            </div>
          ) : (
            ""
          )}
          {couponCode.amount > 0 ? (
            <div>
              <h6 className="text-left">Discount</h6>
              <h6 className="text-right">-Ghc{couponCode.amount}</h6>
            </div>
          ) : (
            ""
          )}
          <div>
            <h6 className="text-left">Total</h6>
            <h6 className="text-right">
              Ghc
              {couponCode
                ? total + deliveryFee - couponCode.amount
                : total + deliveryFee}
            </h6>
          </div>
          {isCouponCode && <p className="coupon-alert">{couponMsg}</p>}
          <div className="order-pink-line"></div>
        </div>
        <div className="order-privacy-note">
          <p>
            Terms and conditions apply for every purchase, kindly ckeck{" "}
            <Link to="/return-policy"> return policy</Link>.
          </p>
        </div>
        <div className="place-order">
          <button type="submit" className="place-order-btn">
            PLACE ORDER
          </button>
        </div>
      </div>
    </section>
  );
}

export default YourOrder;
