import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { setSearchField } from "../features/product/productSlice";
import styled from "styled-components";

const SearchedSec = styled.section`
  aside {
    z-index: 2;
    background-color: white;
    position: fixed;
    width: 348px;
    // height: 0px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding: 8px;
  }

  @media (max-width: 1360px) {
    aside {
      top: 7rem;
      width: 70%;
    }
  }
`;

export function SearchComp({ search, setSearch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, searchField } = useSelector((store) => store.products);

  const [searchedItems, setSearchedItems] = useState([]);
  const [limit, setLimit] = useState(4);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!search || search === " ") {
      return;
    }
    dispatch(setSearchField(search));
    navigate(`/products/search/${search}`);
    setSearch("");
    setLimit(0);
    dispatch(setSearchField(""));
  };

  const fetch = useCallback(() => {
    if (searchField) {
      setSearchedItems(
        products?.filter((item) => {
          return (
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.tags.toLowerCase().includes(search.toLowerCase())
          );
        })
      );
    }
  }, [products, search, searchField]);

  useEffect(() => {
    dispatch(setSearchField(search));
    fetch();
  }, [search, dispatch, fetch]);

  useEffect(() => {
    if (search.length > 1) {
      setLimit(4);
    }
  }, [search]);

  return (
    <SearchedSec>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className="search-field"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="search-btn">SEARCH</button>

        {searchedItems.length > 0 && search ? (
          <>
            <aside>
              {searchField && (
                <ul>
                  {searchedItems.slice(0, limit).map((item) => {
                    return (
                      <li
                        key={item.id}
                        onClick={() => {
                          navigate(`/product/${item.id}`);
                          setSearchedItems([]);
                          setSearch("");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </aside>
          </>
        ) : (
          ""
        )}
      </form>
    </SearchedSec>
  );
}

export function SearchCompMobile({ search, setSearch }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, searchField } = useSelector((store) => store.products);

  const [searchedItems, setSearchedItems] = useState([]);
  const [limit, setLimit] = useState(4);

  const handleSubmit = (e) => {
    if (!search || search === " ") {
      return;
    }
    e.preventDefault();
    dispatch(setSearchField(search));
    navigate(`/products/search/${search}`);
    setSearch("");
    setLimit(0);
  };

  const fetch = useCallback(() => {
    if (searchField) {
      setSearchedItems(
        products?.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [products, search, searchField]);

  useEffect(() => {
    dispatch(setSearchField(search));
    fetch();
  }, [search, dispatch, fetch]);

  useEffect(() => {
    if (search.length > 1) {
      setLimit(4);
    }
  }, [search]);

  return (
    <SearchedSec>
      <form onSubmit={handleSubmit}>
        <FiSearch />
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {searchedItems.length > 0 && search ? (
          <>
            <aside>
              {searchField && (
                <ul>
                  {searchedItems.slice(0, limit).map((item) => {
                    return (
                      <li
                        key={item.id}
                        onClick={() => {
                          navigate(`/product/${item.id}`);
                          setSearchedItems([]);
                          setSearch("");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </aside>
          </>
        ) : (
          ""
        )}
      </form>
    </SearchedSec>
  );
}
